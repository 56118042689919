import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CommonLoadingIndicator from "./CommonLoadingIndicator";
import { Symptom } from "../StudyDash/types";

type TableProps = {
  data: Array<Symptom>;
};

// Define a type for severity levels
type SeverityLevel = 1 | 2 | 3 | 4 | 5;

// Define a dictionary type
const severityDictionary: Record<SeverityLevel, string> = {
  1: "Very Low",
  2: "Low",
  3: "Moderate",
  4: "High",
  5: "Very High",
};

// Define a type for general feeling levels
type GeneralFeelingLevel = 1 | 2 | 3 | 4 | 5;

// Define a dictionary for general feeling
const generalFeelingDictionary: Record<GeneralFeelingLevel, string> = {
  1: "Very Bad",
  2: "Bad",
  3: "Neutral",
  4: "Good",
  5: "Very Good",
};

function getGeneralFeelingLabel(feeling: GeneralFeelingLevel): string {
  return generalFeelingDictionary[feeling];
}

// Function to map severity value to label
export function getSeverityLabel(severity: SeverityLevel): string {
  return severityDictionary[severity];
}

export function transformDataToRows(
  data: Array<Symptom>,
  allColumns: boolean = false
) {
  return data.map((value: any) => {
    return {
      ...(allColumns ? value : {}),
      id: value.$id,
      symptomTime: new Date(value.symptomTime).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
      severity: getSeverityLabel(value.severity),
      name: value.name,
      generalFeeling: getGeneralFeelingLabel(value.generalFeeling.toString()),
      tags: value.tags,
      symptomCategory: value.symptomCategory,
      location: value.location,
    };
  });
}

function SymptompsTable({ data }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  const columns = [
    // { field: "$id", headerName: "Id", width: 200 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "symptomCategory", headerName: "Symptom Category", width: 200 },
    { field: "symptomTime", headerName: "Symptom Time", width: 200 },
    { field: "severity", headerName: "Severity", width: 200 },
    { field: "generalFeeling", headerName: "General Feeling", width: 200 },
    { field: "tags", headerName: "Tags", width: 200 },
    { field: "location", headerName: "Location", width: 200 },
    { field: "userIdInt", headerName: "User ID", width: 200 },
    { field: "additionalNotes", headerName: "Additional Notes", width: 200 },
  ];

  useEffect(() => {
    if (!data) return;
    try {
      const transformedRows = transformDataToRows(data);

      setTableRows(transformedRows);
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if (tableRows && dataLoaded) {
    return (
      <DataGrid
        data-testid="symptoms-table"
        rows={tableRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[5, 10, 20]}
      />
    );
  } else {
    return <CommonLoadingIndicator />;
  }
}

export default SymptompsTable;

import { MenuItem } from "@mui/material";
import "../GraphDisplay.css";
import { CustomMUIDropDown, FormControlStyled } from "./customMUIStyles";

const GraphTypeDropDown = ({ options, disabled, onChangeFunction, value }) => {
  return (
    <FormControlStyled style={{ minWidth: 170, width: "90%" }}>
      <CustomMUIDropDown
        defaultValue={value}
        value={value}
        onChange={onChangeFunction}
        size="small"
        disabled={disabled}
        sx={{ marginLeft: -1, marginTop: 0, marginBottom: 0 }}
        fullWidth>
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          );
        })}
      </CustomMUIDropDown>
    </FormControlStyled>
  );
};

export default GraphTypeDropDown;

import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import PatientInformationCard from "./components/IndividualProfile/PatientInformationCard";
import IndividualPatientOverviewCard from "./components/IndividualProfile/IndividualPatientOverviewCard";
import PatientGraphComponent from "./components/IndividualProfile/ProfileGraphComponent";
import TitleBar from "../../components/common/TitleBar";
import "./Dashboard.css";
import "../../overall.css";
import { useParams, useNavigate } from "react-router-dom";
import UpgradeToViewPatients from "./components/Modals/UpgradeToViewPatients";

type IndividualPatientProps = {
  patients: AzureUserInfo[];
};

type AzureUserInfo = {
  displayName: string;
  givenName: string;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string | null;
  officeLocation?: string | null;
  preferredLanguage?: string | null;
  surname: string;
  userPrincipalName: string;
  patientID: string;
  id: string;
  BusinessPhones?: string[] | null;
};

function findPatientByID(
  patientsArray: AzureUserInfo[],
  patientID: string | undefined
): AzureUserInfo | false {
  if (!patientID) return false;
  const patient = patientsArray.find(
    (patient) => Number(patient.patientID) === Number(patientID)
  );
  return patient || false;
}

function IndividualPatient(patientData: IndividualPatientProps) {
  const [open, setOpen] = useState(false);
  const { id: patientId } = useParams<string>(); // use useParams hook to get the dynamic ID
  const [firstName, setFirstName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [patientEmail, setPatientEmail] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    const patient = findPatientByID(patientData.patients, patientId);
    if (!patient) {
      setOpen(true); // Open the dialog if the patient is not available
    } else {
      // Update patient information if found
      setFirstName(patient.givenName);
      setSurname(patient.surname);
      setPatientEmail(patient.mail || "");
    }
  }, [patientId, patientData.patients, patientEmail]);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      {open ? (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              width: "612px",
              backgroundColor: "transparent",
            },
          }}>
          <UpgradeToViewPatients onClose={handleClose} />
        </Dialog>
      ) : (
        <div className="parentContainer">
          <div className="mainContainer studyDashboard">
            <>
              <TitleBar
                title={"Patient profile"}
                subtitle={`${firstName} ${surname}`}
              />
            </>
            <div className="cardParentContainer">
              <div className="studyInformation row">
                <PatientInformationCard patientID={patientId ?? ""} />
                <IndividualPatientOverviewCard patientID={patientId ?? ""} />
              </div>
              <PatientGraphComponent
              // graphToDisplay={visualization}
              // macroChecks={macroChecks}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IndividualPatient;

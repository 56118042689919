import React, { useState, useEffect, Suspense } from "react";
import TitleBar from "../../components/common/TitleBar";
import { ReactComponent as StudyIcon } from "../../icons/StudyIconFig.svg";
import LoadingIcon from "../../components/common/LoadingIcon";

//icon imports
import { ReactComponent as PatientsIcon } from "../../icons/PatientsIconFig.svg";

// Material UI imports
import Button from "@mui/material/Button";

import NewStudyModal from "./Modals/NewStudyModal/NewStudyModal";

import "./DoctorHomeDiary.css";
import "../../overall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import InvitePatientModal from "../../components/invitePatientModal/InvitePatientModal";
import { getSignedInUserFullName } from "../../auth/auth";
import { Study } from "./CommonTypes";
const InactivePatientsTable = React.lazy(
  () => import("./InactivePatientsTable")
);

type HomeDashProps = {
  patients: Object;
  allStudyData: Study[];
  handleStudyChange: (newStudy: Study) => void;
  setAllStudyData: React.Dispatch<React.SetStateAction<Study[]>>;
  dashBoardStudyData: any;
};

function DoctorHomeDiary({
  patients,
  allStudyData,
  handleStudyChange,
  setAllStudyData,
  dashBoardStudyData,
}: HomeDashProps) {
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [newStudyModalOpen, setNewStudyModalOpen] = useState(false);
  const [addPatientModalOpen, setAddPatientModalOpen] = useState(false);
  const [fullName, setFullName] = useState("");

  const handleNewStudyOpen = () => {
    setNewStudyModalOpen(true);
  };

  const handleAddPatientOpen = () => {
    setAddPatientModalOpen(true);
  };

  const handlePostStudy = (study: any) => {
    // window.location.reload();
  };

  useEffect(() => {
    setFullName(getSignedInUserFullName());
  }, []);

  function contentToShow() {
    if (!isLoaded) {
      return (
        <div style={{ marginTop: "25%" }}>
          <LoadingIcon />
        </div>
      );
    } else if (allStudyData) {
      return (
        <div>
          <TitleBar title={"Professional Dashboard"} subtitle={fullName} />
          <div className="sumContainer row flex justify-between">
            <div className="box-container w-[60%]">
              <div className="body-text subtitle-text-size mrb-12 font-bold">
                SUMMARY
              </div>
              <div className="leftSumFlexContainer">
                <div className="stats-container row flex justify-start gap-10">
                  <div className="statsCard stats-card-short">
                    <div className="stats-squareStudyContainer">
                      <StudyIcon className="sub-title-icon" />
                      <div className="body-text-size stats-title">Studies</div>
                    </div>
                    <div className="stats-row row">
                      <div className="big-title-size stats-number">
                        {dashBoardStudyData.currentlyActiveStudy || 0}
                      </div>
                    </div>
                    <div className="body-text-size stats-bottomTitle">
                      Active Currently
                    </div>
                  </div>

                  <div className="statsCard stats-card-short">
                    <div className="stats-squareStudyContainer">
                      <PatientsIcon className="sub-title-icon" />
                      <div className="body-text-size stats-title">Patients</div>
                    </div>
                    <div className="stats-row row">
                      <div className="big-title-size stats-number">
                        {dashBoardStudyData.registeredPatients || 0}
                      </div>
                    </div>
                    <div className="body-text-size stats-bottomTitle">
                      Active Currently
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-container w-[38%]">
              <div className="sumRightHeaderContainer">
                <div className="row edit-row flex justify-between">
                  <div className="body-text subtitle-text-size mrb-12 font-bold">
                    QUICK ADD
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-evenly gap-6 h-[70%]">
                <Button
                  onClick={() => {
                    handleNewStudyOpen();
                  }}
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faFileCirclePlus} />}
                  sx={{
                    color: "white",
                    background: "#5FBDD6",
                    borderRadius: "10px",
                    textTransform: "none",
                    width: "fit-content",
                    padding: "5px 20px",
                  }}>
                  Create New Study
                </Button>
                <Button
                  onClick={() => {
                    handleAddPatientOpen();
                  }}
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                  sx={{
                    color: "white",
                    background: "#5FBDD6",
                    borderRadius: "10px",
                    textTransform: "none",
                    width: "fit-content",
                    padding: "5px 20px",
                  }}>
                  Invite Patient
                </Button>
              </div>
            </div>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <InactivePatientsTable
              allStudyData={allStudyData}
              dashBoardStudyData={dashBoardStudyData}
            />
          </Suspense>
          <NewStudyModal
            newStudyModalOpen={newStudyModalOpen}
            setNewStudyModalOpen={setNewStudyModalOpen}
            patients={patients}
            setIsLoaded={setIsLoaded}
            handlePostStudy={handlePostStudy}
          />
          <InvitePatientModal
            show={addPatientModalOpen}
            studies={allStudyData}
            onClose={() => setAddPatientModalOpen(false)}
            studyData={dashBoardStudyData.studies}
          />
        </div>
      );
    }
  }

  return <div className="mainContainer">{contentToShow()}</div>;
}

export default DoctorHomeDiary;

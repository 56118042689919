import { ReactComponent as YourPlanResearch } from "../../img/yourPlanResearch.svg";
import { ReactComponent as YourPlanIndividual } from "../../img/yourPlanIndividual.svg";
// import { ReactComponent as YourPlanResearchTeam } from "../../img/yourPlanResearch.svg";
import { ReactComponent as BulletDown } from "../../img/bullet-down.svg";
import { ReactComponent as BulletUp } from "../../img/bullet-up.svg";
// import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const YourPlan = ({ isDoctor }: { isDoctor: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const detailsRef = useRef<HTMLDivElement>(null);
  const [bullets, setBullets] = useState<String[]>([""]);

  useEffect(() => {
    if (!isDoctor) {
      setBullets([
        "Standard Nutritional + Symptomatic Data Collection",
        "Basic Data Analysis",
      ]);
    } else {
      setBullets([
        "HIPAA protected Standard Nutritional + Symptomatic Data Collection",
        "Basic Data Analysis",
        "250 participant mobile codes",
      ]);
    }
  }, [isDoctor]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    }
  }, [isOpen]);

  return (
    <div className="title-container rounded-[10px] relative flex flex-column gap-6 justify-start">
      <div className="absolute w-full h-[170px]">
        <div className="title-container sky-blue-bg h-[170px] rounded-[10px] flex z-100 items-center gap-6 justify-between p-10 relative">
          {isDoctor ? <YourPlanResearch /> : <YourPlanIndividual />}
          <span className="flex flex-column items-center gap-3">
            <span className="inline-block h-[41px] w-[150px]">
              {/* {!isOpen ? (
                <Button
                  variant="contained"
                  sx={{
                    "color": "#1D9DBF",
                    "border": "1px solid #1D9DBF",
                    "borderRadius": "10px",
                    "backgroundColor": "white",
                    "textTransform": "none",
                    "fontSize": "15px",
                    "width": "150px",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    alert("Must call api to upgrade account");
                  }}>
                  Upgrade Plan
                </Button>
              ) : null} */}
            </span>
            <span
              className="text-white flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              See Details {isOpen ? <BulletUp /> : <BulletDown />}
            </span>
          </span>
        </div>
      </div>
      <div
        ref={detailsRef}
        className={`your-plan-details flex-column flex-col-reverse justify-between p-10 box-container block w-full ${isOpen ? "pt-[200px] h-[500px] " : "h-0"} transition-all duration-300`}>
        <div>
          <h3 className="font-bold text-blue pb-3">
            You have free access to mmd web.
          </h3>
          <h3 className="font-bold text-blue">Would you like to upgrade?</h3>

          <ul className="list-disc pl-4 mt-3">
            {isOpen &&
              bullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
          </ul>
        </div>
        {/* <div
          className={`${isOpen ? "flex gap-6" : "hidden"} transition-all duration-300`}>
          <Button
            variant="contained"
            sx={{
              "color": "white",
              "border": "1px solid #1D9DBF",
              "borderRadius": "10px",
              "backgroundColor": "#1D9DBF",
              "textTransform": "none",
              "fontSize": "15px",
              "width": "150px",
              "&:hover": {
                color: "white",
              },
            }}
            onClick={() => {
              alert("Must call api to delete account");
            }}>
            Upgrade Plan
          </Button>
          <Button
            variant="contained"
            sx={{
              "color": "#1D9DBF",
              "border": "1px solid #1D9DBF",
              "borderRadius": "10px",
              "backgroundColor": "white",
              "textTransform": "none",
              "fontSize": "15px",
              "width": "150px",
              "&:hover": {
                color: "white",
              },
            }}
            onClick={() => {
              setIsOpen(false);
            }}>
            Cancel Plan
          </Button>
        </div> */}
      </div>
    </div>
  );
};
export default YourPlan;

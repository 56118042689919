import { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { ReactComponent as BulletPoint } from "../../../../img/bullet-point.svg";
import { toast, ToastContainer } from "react-toastify";
import { sendPatientPayRequestEmail } from "../../../../API/HomeDiaryCalls";

const UpgradeToViewPatients = ({ onClose }: { onClose: () => void }) => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    if (!isEmailValid(email) || email.length === 0) {
      setValidEmail(false);
      return;
    }
    const emailBody = `${email} requires plan to view patient data.`;
    const subject = `${email} requires plan to view patient data.`;

    sendPatientPayRequestEmail(subject, emailBody);
    toast("Email has been sent!");

    setTimeout(() => {
      onClose();
    }, 4000);
  };

  return (
    <Box>
      {/* Background Box with Border */}
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          width: "611px",
          height: "385px",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: 3,
          border: "4px solid #5fbdd6",
        }}>
        {/* Upgrade Text */}
        <Box width={"328px"}>
          <Typography
            sx={{
              color: "#1c9dbf",
              fontSize: "16px",
              fontWeight: "900",
              fontFamily: "Lato",
              textTransform: "uppercase",
              textAlign: "center",
              letterSpacing: "tight",
            }}>
            UpGrade
          </Typography>

          {/* Upgrade Message */}
          <Typography
            sx={{
              top: "120px",
              left: "133px",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "Lato",
              color: "black",
              letterSpacing: "tight",
            }}>
            You do not have sufficient permission to view this user's data. Please upgrade to a paid plan. Enter your email
            below to be contacted. If this is a mistake, please let the team know after they reach out to you.
          </Typography>
        </Box>
        <Box display={"flex"} sx={{ marginTop: "20px", marginBottom: "20px" }}>
          {/* Email Input */}
          <Box
            sx={{
              width: "328px",
              height: "62px",
            }}>
            <Typography
              sx={{
                color: "#1c9dbf",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "Lato",
              }}>
              Email
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                position: "relative",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
                borderColor: "#5fbdd6",
                input: {
                  paddingLeft: "17px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontFamily: "Lato",
                  color: "#2f2f2f",
                },
              }}
            />
            {!validEmail ? (
              <span className="text-red-500 text-xs">
                Please enter a valid email
              </span>
            ) : null}
          </Box>
        </Box>
        {/* Send Button */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            width: "200px",
            height: "48px",
          }}>
          <Button
            onClick={onClose}
            sx={{
              width: "92.36px",
              height: "48px",
              background: "linear-gradient(to right, #c7c5c5, #959595)",
              borderRadius: "5px",
              boxShadow: 2,
            }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: "bold",
                fontFamily: "Lato",
                letterSpacing: "tight",
                color: "white",

                textTransform: "none",
              }}>
              Close
            </Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              width: "90px",
              height: "48px",
              background: "linear-gradient(to right, #1c9dbf, #12667c)",
              borderRadius: "5px",
              boxShadow: 2,
            }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: "bold",
                fontFamily: "Lato",
                letterSpacing: "tight",
                paddingRight: "3px",
                color: "white",
                textTransform: "none",
              }}>
              Send
            </Typography>
            <BulletPoint height={20} width={20} />
          </Button>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default UpgradeToViewPatients;

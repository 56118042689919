import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import { Select, MenuItem } from "@mui/material";
import "../GraphDisplay.css";
import DownloadButton from "../../../img/downloadButton.svg";

const CustomMUIDropDown = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    "position": "relative",
    "backgroundColor": "#FAFAFA",
    "color": "#999999",
    "border": "1px solid #5FBDD6",
    "fontSize": 12,
    "fontWeight": "bold",
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 6,
      borderColor: "#5FBDD6",
    },
  },
}));

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  display: "flex",
  alignItems: "center", // Ensures vertical alignment
  flexDirection: "row", // Ensures components are in the same line
  marginTop: theme.spacing(1),
}));

const FileTypeDownloadSelector = ({
  options,
  disabled,
  onChangeFunction,
  value,
  handleDownloadData,
}) => {
  return (
    <FormControlStyled>
      <CustomMUIDropDown
        defaultValue={value}
        value={value}
        onChange={onChangeFunction}
        size="small"
        disabled={disabled}
        sx={{ minWidth: 150 }}
        fullWidth>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </CustomMUIDropDown>

      <img
        src={DownloadButton}
        alt="Download the table data"
        className="icon-image w-10 ml-2 cursor-pointer hover:opacity-65 transition-opacity"
        onClick={handleDownloadData}
      />
    </FormControlStyled>
  );
};

export default FileTypeDownloadSelector;

import { useState } from "react";
import StudyGraphDisplay from "./StudyGraphDisplay";

import { FetchInfo } from "../../../../components/common/types/FetchInfo";

import Box from "@mui/material/Box";

import {
  getStudyData,
  getSymptomsData,
} from "../../../../API/StudyDashboardCalls";

//Helper Functions
import MacroBarGraph from "../Graphs/MacroBarGraph";
import MacroLineGraph from "../Graphs/MacroLineGraph";
import CaloriesBarGraph from "../Graphs/CaloriesBarGraph";
import CaloriesLineGraph from "../Graphs/CaloriesLineGraph";
import { json2csv } from "json-2-csv";
import { getSignedInUserFullName } from "../../../../auth/auth";
import SymptompsTable, {
  transformDataToRows,
} from "../IndividualProfile/SymptompsTable";
import MacronutrientTable from "../IndividualProfile/MacronutrientTable";
import MicronutrientTable from "../IndividualProfile/MicronutrientTable";
import CaloriesTable from "../IndividualProfile/CaloriesTable";
import { formatGraphData } from "../../../../utils/helperFunctions";

import "./StudyGraphComponent.css";
import { MacroChecks } from "../../../../components/common/types/MacroChecks";
import { toast } from "react-toastify";
import allSymptoms from "../../../../utils/globalConstants";
import { Symptom } from "./types";
import CommonLoadingIndicator from "../IndividualProfile/CommonLoadingIndicator";
import { micronutrientsColumns } from "./micronutrientsColumns";
import SymptompsTreemap from "../IndividualProfile/SymptompsTreemap";

const convertToValidSelectOptions = (options: string[]) => {
  return options.map((option) => ({
    value: option.toLowerCase().replace(/\s/g, ""),
    text: option,
  }));
};

const getSymptomsOptions = () => {
  const coldOptions = convertToValidSelectOptions(allSymptoms.cold);
  const painOptions = convertToValidSelectOptions(allSymptoms.pain);
  const stomachOptions = convertToValidSelectOptions(allSymptoms.stomach);
  const allSymptomsOptions = {
    all: [...coldOptions, ...painOptions, ...stomachOptions],
  };
  return allSymptomsOptions;
};

function StudyGraphComponent({ studyId }: { studyId: Number }) {
  const allSymptoms = getSymptomsOptions();

  const [data, setData] = useState<Array<any>>([]);
  const [fetchInfo, setFetchInfo] = useState<FetchInfo>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [graphType, setGraphType] = useState("table");
  const [symptomCategory, setSymptomCategory] = useState("all");
  const [rawDataChecked, setRawDataChecked] = useState(false);
  const [symptomsSelected, setSymptomsSelected] = useState(
    Object.values(allSymptoms.all).map((symptom) => symptom.value)
  );
  const [visualization, setVisualization] = useState<string>("macronutrients");
  // const [previousVisualization, setPreviousVisualization] = useState("");
  // const [enableLoadData, setEnableLoadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [macroChecks, setMacroChecks] = useState<MacroChecks>({
    fatsChecked: true,
    carbsChecked: true,
    proteinsChecked: true,
    caloriesChecked: true,
  }); // is the macronutrient checked or not

  const [visualizationHeader, setVisualizationHeader] = useState<string>("");

  const handleDownloadData = () => {
    if (!data.length) {
      toast.error("No data to download");
      return;
    }
    const fullName = getSignedInUserFullName();
    const symptomCondition = visualization === "symptoms";
    let csv = "";
    if (symptomCondition && !rawDataChecked) {
      const symptomsData = transformDataToRows(data, true);
      csv = json2csv(symptomsData);
    } else {
      csv = json2csv(data);
    }
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${
      fullName.includes("undefined") ? "report" : fullName
    }.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getDataForSymptoms = () => {
    getSymptomsData(studyId, startDate.toISOString(), endDate.toISOString())
      .then((result) => {
        setData(result);
      })
      .catch((e) => {
        console.log("getDataForSymptoms:: Server Error");
      })
      .finally(() => {
        setLoading(false);
        // setEnableLoadData(false);
      });
  };

  const getDataForNutrients = (userId: string) => {
    getStudyData(
      startDate.toISOString(),
      endDate.toISOString(),
      userId,
      studyId
    )
      .then((result) => {
        setData(result?.$values);
      })
      .catch((e) => {
        console.log("getDataForNutrients:: Server Error");
      })
      .finally(() => {
        setLoading(false);
        // setEnableLoadData(false);
      });
  };

  const getData = (userId: string) => {
    // if (!enableLoadDataHandler()) return;
    if (userId) {
      setLoading(true);
      if (graphType === "treemap") {
        setVisualizationHeader(`Treemap of ${symptomCategory} symptoms`);
      } else {
        setVisualizationHeader(`Average ${visualization} input over time`);
      }
      const symptomCondition = visualization === "symptoms";
      if (symptomCondition) {
        getDataForSymptoms();
      } else {
        getDataForNutrients(userId);
      }
    }
  };

  const getFilteredSymptomsData = (data: Symptom[]) => {
    if (!data.length) return [];
    let filteredData = data;
    if (symptomCategory !== "all") {
      const responseCategoryNames: { [key: string]: string } = {
        cold: "ColdFlu",
        stomach: "Stomach Bug",
        pain: "Pain",
      };

      filteredData = data.filter(
        (d: Symptom) =>
          d.symptomCategory === responseCategoryNames[symptomCategory]
      );
    }
    if (symptomsSelected.length) {
      filteredData = data.filter((d: Symptom) => {
        return symptomsSelected.includes(d.name.toLocaleLowerCase());
      });
    }

    return filteredData;
  };

  const renderGraph = () => {
    if (graphType === "table") {
      switch (visualization) {
        case "symptoms":
          const filteredData = getFilteredSymptomsData(data);
          return <SymptompsTable data={filteredData} />;
        case "macronutrients":
          return <MacronutrientTable data={data} />;
        case "micronutrients":
          return (
            <MicronutrientTable data={data} columns={micronutrientsColumns} />
          );
        default:
          return <CaloriesTable data={data} />;
      }
    }

    if (graphType === "treemap") {
      const filteredData = getFilteredSymptomsData(data);
      return <SymptompsTreemap data={filteredData} />;
    }
    if (graphType === "line") {
      return visualization === "macronutrients" ? (
        <MacroLineGraph
          graphData={formatGraphData(data)}
          macroChecks={macroChecks}
        />
      ) : (
        <CaloriesLineGraph graphData={formatGraphData(data)} />
      );
    } else {
      return visualization === "macronutrients" ? (
        <MacroBarGraph
          graphData={formatGraphData(data)}
          macroChecks={macroChecks}
        />
      ) : (
        <CaloriesBarGraph graphData={formatGraphData(data)} />
      );
    }
  };

  const handleVisualizationChange = (value: string) => {
    // setPreviousVisualization(visualization);
    setVisualization(value);
  };

  // const enableLoadDataHandler = useCallback(() => {
  //   if (graphType === "treemap") {
  //     setVisualizationHeader(`Treemap of ${symptomCategory} symptoms`);
  //   } else {
  //     setVisualizationHeader(`Average ${visualization} input over time`);
  //   }
  //   if (enableLoadData) return true;
  //   const shouldEnableLoadData = () => {
  //     const calorieCondition =
  //       visualization === "calories" &&
  //       (previousVisualization === "micronutrients" ||
  //         previousVisualization === "macronutrients");

  //     const previousCalorieCondition =
  //       previousVisualization === "calories" &&
  //       (visualization === "micronutrients" ||
  //         visualization === "macronutrients");

  //     const symptomCondition = visualization === "symptoms";

  //     return calorieCondition || previousCalorieCondition || symptomCondition;
  //   };

  //   console.log({ shouldEnableLoadData: shouldEnableLoadData() });
  //   if (shouldEnableLoadData()) {
  //     setEnableLoadData(true);
  //     return true;
  //   }
  //   return false;
  // }, [
  //   enableLoadData,
  //   visualization,
  //   previousVisualization,
  //   graphType,
  //   symptomCategory,
  // ]);

  // useEffect(() => {
  //   enableLoadDataHandler();
  // }, [visualization, previousVisualization, enableLoadDataHandler]);

  // useEffect(() => {
  //   setEnableLoadData(
  //     (!lastStartDate && !lastEndDate) ||
  //       startDate.getTime() !== lastStartDate?.getTime() ||
  //       endDate.getTime() !== lastEndDate?.getTime()
  //   );
  // }, [lastStartDate, startDate, lastEndDate, endDate]);

  return (
    <Box className="study-graphContainer">
      <Box className="study-leftGraphContainer">
        <StudyGraphDisplay
          isStudyDash={false}
          setLoading={setLoading}
          setVisualization={setVisualization}
          setFetchInfo={setFetchInfo}
          setData={setData}
          api_data={data}
          handleDownloadData={handleDownloadData}
          handleLoadData={getData}
          graphType={graphType}
          symptomCategory={symptomCategory}
          symptomsSelected={symptomsSelected}
          rawDataChecked={rawDataChecked}
          onRawDataChange={() => setRawDataChecked(!rawDataChecked)}
          visualization={visualization}
          handleStartDateChange={setStartDate}
          handleEndDateChange={setEndDate}
          handleGraphTypeChange={setGraphType}
          handleSymptomCategoryChange={setSymptomCategory}
          handleSymptomSelectedChange={setSymptomsSelected}
          handleVisualizationChange={handleVisualizationChange}
          startDate={startDate}
          endDate={endDate}
          setMacroChecks={setMacroChecks}
          macroChecks={macroChecks}
        />
      </Box>
      <Box
        className="box-container study-rightGraphContainer"
        sx={{ display: "inline-grid", overflowX: "auto", width: "100%" }}
        data-testid="graph-container">
        {fetchInfo === undefined && !loading ? (
          <div className="screen-center">
            <div className="body-text-size body-text">
              Please fill in the choices to the left and click "Load Table"
            </div>
          </div>
        ) : loading ? (
          <CommonLoadingIndicator />
        ) : (
          <>
            <span className="card-title-text">{visualizationHeader}</span>
            {renderGraph()}
          </>
        )}
      </Box>
    </Box>
  );
}

export default StudyGraphComponent;

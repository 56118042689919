import { MenuItem, ListItemText, Checkbox } from "@mui/material";
import "../GraphDisplay.css";
import { CustomMUIDropDown, FormControlStyled } from "./customMUIStyles";

const SymptomsSelector = ({
  options,
  disabled,
  onChangeFunction,
  value,
  categorySelected,
}) => {
  const optionsFiltered = options[categorySelected];
  const allSelected = value.length === optionsFiltered.length; // Check if all options are selected
  return (
    <FormControlStyled style={{ minWidth: 170, width: "90%" }}>
      <CustomMUIDropDown
        multiple
        value={value}
        onChange={onChangeFunction}
        size="small"
        disabled={disabled}
        sx={{ marginLeft: -1, marginTop: 0, marginBottom: 0 }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "No symptoms selected";
          } else if (selected.length === 1) {
            return selected[0];
          }
          return `${selected.length}/${optionsFiltered.length} selected`;
        }}
        fullWidth>
        <MenuItem value="all">
          <ListItemText primary="All symptoms selected" />
          <Checkbox checked={allSelected} sx={{ display: "inline" }} />
        </MenuItem>
        {optionsFiltered.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "#5b5b5b",
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Lato",
              paddingLeft: "14px",
              height: "33px",
            }}>
            <ListItemText primary={option.text} />
            <Checkbox
              checked={value.includes(option.value)}
              sx={{ marginLeft: "5px" }}
            />
          </MenuItem>
        ))}
      </CustomMUIDropDown>
    </FormControlStyled>
  );
};

export default SymptomsSelector;

// <FormControl
//             sx={{
//               "m": 1,
//               "width": "100%",
//               "height": 44,
//               "top": "14px",
//               "position": "absolute",
//               "& .MuiOutlinedInput-root": {
//                 backgroundColor: "#ffffff",
//                 borderRadius: "21px",
//                 border: "1px solid #5fbdd6",
//                 boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.10)",
//                 height: "100%",
//               },
//               "& .MuiSelect-select": {
//                 paddingLeft: "14px",
//                 fontSize: "13px",
//                 fontWeight: "bold",
//                 fontFamily: "Lato",
//                 color: "#1c9dbf",
//               },
//               "& .MuiSvgIcon-root": {
//                 color: "#1c9dbf", // Change arrow color to #1c9dbf
//                 right: "14px",
//               },
//             }}>

//   </FormControl>

/* <Select
              value={patientsSelected}
              multiple
              onChange={handlePatientChange}
              renderValue={(selected) => {
                if (selected.includes("allPatients")) {
                  return "All Patients selected";
                } else if (selected.length === 1) {
                  return patients.find((p) => p.id.toString() === selected[0])
                    .name;
                }
                return `${selected.length} patients selected`;
              }}>
              
            </Select> */

import { MenuItem } from "@mui/material";
import "../GraphDisplay.css";
import { CustomMUIDropDown, FormControlStyled } from "./customMUIStyles";

const VisualizationDropDown = ({ onChangeFunction, value, options }) => {
  return (
    <FormControlStyled style={{ minWidth: 170, width: "90%" }}>
      <CustomMUIDropDown
        defaultValue={value}
        value={value}
        onChange={onChangeFunction}
        size="small"
        sx={{ marginLeft: -1, marginTop: 0, marginBottom: 0 }}
        fullWidth>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </MenuItem>
        ))}
      </CustomMUIDropDown>
    </FormControlStyled>
  );
};

export default VisualizationDropDown;

import { useCallback, useEffect, useState } from "react";
import { fetchData } from "../../../../API/CommonFunctions";
import { getAccessToken } from "../../../../auth/auth";
import ActivityCard from "../../../../components/common/ActivityCard";
import LoadingIcon from "../../../../components/common/LoadingIcon";
import { initFetchRequest } from "../../../../utils/helperFunctions";
import "../../Dashboard.css";
import "../../../../overall.css";

interface IndividualPatientOverviewCardProps {
  patientID: string | null;
}

function IndividualPatientOverviewCard({
  patientID,
}: IndividualPatientOverviewCardProps) {
  const [data, setData] = useState<any>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const getProfileOverview = useCallback(async () => {
    setDataLoaded(false);
    setError(null);
    try {
      var token = await getAccessToken();
      const apiEndpoint =
        patientID !== null
          ? "/api/Patients/ProfileOverview/" + patientID
          : "/api/Patients/UserProfileOverview";
      const request = initFetchRequest(apiEndpoint, token);
      const response = await fetchData(request);
      setData(response);
      setDataLoaded(true);
      if (!response) {
        setError("Error loading patient overview");
      }
    } catch (error) {
      setError("Error loading patient overview");
      setDataLoaded(true);
    }
  }, [patientID]);

  useEffect(() => {
    getProfileOverview();
  }, [getProfileOverview]);
  return (
    <div className="po-mainContainer box-container">
      {dataLoaded === false ? (
        <div className="small-loadingContainer">
          <LoadingIcon />
        </div>
      ) : error !== null ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          <div className="row edit-row">
            <div className="sub-title-size sub-title mrb-12">
              Patient Overview
            </div>
            <div className="edit-button">
              {/* <button className="text-button mrb-12">Add a patient</button> */}
            </div>
          </div>
          <div className="body-text body-text-size mrb-12">This Week</div>

          <div className="stats-container row">
            <ActivityCard
              cardType={"Meals"}
              data={data.mealsWeeklyRecentlyLogged}
              label={"Recently Logged"}></ActivityCard>
            <ActivityCard
              cardType={"Symptoms"}
              data={data.symptomsWeeklyRecentlyLogged}
              label={"Recently Logged"}></ActivityCard>
          </div>
        </>
      )}
    </div>
  );
}

export default IndividualPatientOverviewCard;

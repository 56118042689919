import { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  initNewMsalObject,
  signIn,
  isUserADoctor,
  isUserSignedIn,
  getUserName,
  getAccountID,
  createUserDBAndAddToPatientGroup,
} from "../auth/auth";
import { getAllPatients } from "../API/PatientDirectoryCalls";

//Material UI Imports
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { theme } from "../utils/materialUI";
// Datepicker import

// Components
import NavBar from "./navigation/NavBar";
import Loading from "./common/Loading";
import HomePage from "../pages/HomePageResponsive";
import OnboardingPage from "../pages/OnboardingPage";
import IndividualProfile from "../pages/DashboardPages/IndividualProfileDash";
import DoctorHomeDiary from "../pages/HomeDiary/DoctorHomeDiary";
import StudyDashboard from "../pages/DashboardPages/StudyDashboard";
import PatientDirectory from "../pages/PatientDirectory/PatientDirectory";
import SettingsPage from "../pages/AccountSettings/SettingsPage";
import TermsAndConditions from "./terms/termsandconditions";

import IndependentUserProfileDashboard from "../pages/DashboardPages/IndependentUserProfileDashboard";

// React Icons
import { ReactComponent as HomeIcon } from "../icons/HomeIcon.svg";
import { ReactComponent as PatientDirectoryIcon } from "../icons/PatientDirectoryIcon.svg";
import { ReactComponent as StudyDashboardIcon } from "../icons/StudyDashboardIcon.svg";
import { ReactComponent as SettingsIcon } from "../icons/SettingsIcon.svg";

// CSS Imports
import "./App.css";
import {
  getAllStudyIDs,
  getAllStudyInformation,
  getStudyDashboardData,
} from "../API/HomeDiaryCalls";
import MobileWarningModal from "./common/MobileWarningModal";
import IndependentUserStudies from "../pages/Studies/IndependentUserStudies";

// Custom TS Types
type FixMeLater = any; // TODO: Fix FixMeLater type
type NavRouteType = {
  path: string;
  exact: boolean;
  navIcon: JSX.Element;
  navText: string;
  main: JSX.Element;
}[];

type Study = {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<any>;
  };
  studyID: Number;
};

// Instantiate MSAL
initNewMsalObject(false); //user not logging in

function App() {
  // ---- App State ----
  const [tosChecked, setTosChecked] = useState<boolean>(
    localStorage.getItem("termsAgreed") === getAccountID() || false
  );
  const [isDoc, setIsDoc] = useState<boolean | null>(null);
  const [, setUsername] = useState<string>("");
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [allStudyData, setAllStudyData] = useState<Study[]>([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Doctor navigation routes for when the type of user is a doctor
  const [doctorNavRoutes, setDoctorNavRoutes] = useState<NavRouteType>([]);

  // Patient profile routes used
  const [patientProfileRoutes, setPatientProfileRoutes] =
    useState<NavRouteType>([]);

  // Account navigation route used when the type user
  const [accountRoute] = useState<NavRouteType>([]);

  // Messages navigation route used when the type user
  const [messageRoute] = useState<NavRouteType>([]);

  // Patient navigation routes for when the type of user is a patient
  const [patientNavRoutes, setPatientNavRoutes] = useState<NavRouteType>([]);

  const onAgreeTerms = async () => {
    setTosChecked(true);
    localStorage.setItem("termsAgreed", getAccountID() || "");
  };

  async function doesUserHaveValidSession() {
    let isSignedIn = isUserSignedIn();
    if (isSignedIn) {
      let isDoctor = false;

      // on first signin, must cache isDoctor
      if (localStorage.getItem("isDoctor") === null) {
        isDoctor = await isUserADoctor();

        // Caching isDoctor so that we don't need to make repeat calls to
        // isUserADoctor() API call on page reloads. This cache will be
        // wiped when the user signs out
        localStorage.setItem("isDoctor", isDoctor.toString());
      } else {
        isDoctor = localStorage.getItem("isDoctor") === "true";
      }
      await createUserDBAndAddToPatientGroup();
      // Note: This code below is effectively casting the string "true"
      // or "false" from local storage to a boolean
      if (isDoctor) {
        setIsDoc(true);
        populateDoctorNavRoutes();
      } else {
        populatePatientNavRoutes();
      }
    } else if (
      window.localStorage["msal.error.description"] &&
      window.localStorage["msal.error.description"].includes("AADB2C90118")
    ) {
      // window.localStorage.clear();
      delete window.localStorage["msal.error.description"];
      delete window.localStorage["isDoctor"];
      initNewMsalObject(true);
    }
    return true;
  }

  // TODO: Can we lazy load this data?
  const populateDoctorNavRoutes = async () => {
    const patientsList = await getAllPatients();
    let studiesData: Study[] = [];
    const response = await getAllStudyIDs();
    if (response !== undefined) {
      try {
        const r = await getAllStudyInformation(response);
        if (r.$values) {
          studiesData = r.$values;
        } else {
          console.error("Error in getAllStudyInformation response");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error in getAllStudyInformation", error);
        window.location.reload();
      }
    }
    const dashBoardStudyData = await getStudyDashboardData();

    setPatientProfileRoutes([
      {
        path: `/user/profile/:id`,
        exact: true,
        navIcon: (
          <PatientDirectoryIcon className="nav-icon icon-primary icon-secondary" />
        ), // add a navIcon property
        navText: "Patient Profile", // add a navText property
        main: <IndividualProfile patients={patientsList} />,
      },
    ]);
    setAllStudyData(studiesData);
    addPatientProfilePageRouteToDoctorNavRoutes(
      patientsList,
      studiesData,
      dashBoardStudyData
    );

    // Update state
    setUsername(getUserName());
    setIsDataLoaded(true);
  };

  const handleStudyChange = (newStudy: Study) => {
    setAllStudyData([...allStudyData, newStudy]);
  };

  const addPatientProfilePageRouteToDoctorNavRoutes = (
    patientsList: FixMeLater,
    allStudyData: Study[],
    dashBoardStudyData: any
  ) => {
    const newDoctorNavRoutes = doctorNavRoutes.concat([
      {
        path: "/",
        exact: true,
        navIcon: <HomeIcon className="nav-icon home-icon" />,
        navText: "Home Diary",
        main: (
          <DoctorHomeDiary
            patients={patientsList}
            allStudyData={allStudyData}
            handleStudyChange={handleStudyChange}
            setAllStudyData={setAllStudyData}
            dashBoardStudyData={dashBoardStudyData}
          />
        ),
      },
      {
        path: "/dashboard",
        exact: true,
        navIcon: (
          <StudyDashboardIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Study Dashboard",
        main: (
          <StudyDashboard
            patients={patientsList}
            allStudyData={allStudyData}
            dashBoardStudyData={dashBoardStudyData}
          />
        ),
      },
      {
        path: "/patients",
        exact: true,
        navIcon: (
          <PatientDirectoryIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Patient Directory",
        main: <PatientDirectory patients={patientsList} />,
      },
      {
        path: "/settings",
        exact: true,
        navIcon: (
          <SettingsIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Account Settings",
        main: <SettingsPage />,
      },
      //************** FOR DEVELOPMENT *************** */
      // {
      //   path: "/api",
      //   exact: true,
      //   navIcon: <FaDev className="nav-icon icon-primary icon-secondary" />,
      //   navText: "API Tester",
      //   main: <RawDataPage getAccessToken={getAccessToken} />,
      // },
    ]);
    setDoctorNavRoutes(newDoctorNavRoutes);
  };

  const populatePatientNavRoutes = () => {
    const userName = getUserName();
    //TODO: Change patient's paths when needed and fix home page
    setPatientNavRoutes([
      {
        path: "/",
        exact: true,
        navIcon: (
          <StudyDashboardIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "myDashboard",
        main: <IndependentUserProfileDashboard />,
      },
      {
        path: "/studies",
        exact: true,
        navIcon: (
          <PatientDirectoryIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Studies",
        main: <IndependentUserStudies />,
      },
      {
        path: "/settings",
        exact: true,
        navIcon: (
          <SettingsIcon className="nav-icon icon-primary icon-secondary" />
        ),
        navText: "Account Settings",
        main: (
          <SettingsPage
          // logout={signOut}
          // getAccessToken={getAccessToken}
          // uri="/dashboard"
          />
        ),
      },
    ]);

    setUsername(userName);
    setIsDataLoaded(true);
  };

  // On first component mount, check if a valid user session exists
  // and automatically redirect user to dashboard.
  useEffect(() => {
    const validateUserSession = async () => {
      await doesUserHaveValidSession();
    };
    validateUserSession();

    // Below comment silences a warning from React
    // TODO: Be a good React dev and follow the React-y way
    //       addressing this warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---- Render JSX Elements ----

  // If signed in, go to the dashboard
  if (isUserSignedIn()) {
    if (!tosChecked) {
      return (
        <div className="loadingContainer">
          <TermsAndConditions onAgree={onAgreeTerms} />
        </div>
      );
    }
    if (!isDataLoaded) {
      return (
        <div className="loadingContainer">
          <Loading />
        </div>
      );
    }

    const userNavRoutes: NavRouteType = [];
    if (isDoc) {
      userNavRoutes.push(
        ...doctorNavRoutes,
        ...patientProfileRoutes,
        ...messageRoute,
        ...accountRoute
        // ...defaultNavRoutes,
      );
    } else {
      userNavRoutes.push(
        ...patientNavRoutes,
        ...messageRoute,
        ...accountRoute
        // ...defaultNavRoutes
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <>
          <Router>
            <div className="navbarDiv">
              {isDoc ? (
                <NavBar
                  routes={[
                    ...doctorNavRoutes,
                    ...messageRoute,
                    ...accountRoute,
                    // ...defaultNavRoutes
                  ]}
                />
              ) : (
                <NavBar
                  routes={[
                    ...patientNavRoutes,
                    ...messageRoute,
                    ...accountRoute,
                    // ...defaultNavRoutes,
                  ]}
                />
              )}
            </div>
            <Routes>
              {userNavRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.main}
                />
              ))}
            </Routes>
          </Router>
          {isMobile ? <MobileWarningModal /> : null}
        </>
      </ThemeProvider>
    );
  }

  // If the user is not logged in, render the home routes
  const homeRoutes = [
    {
      path: "/",
      main: <HomePage signin={signIn} />,
    },
    {
      path: "/onboarding",
      main: <OnboardingPage signin={signIn} />,
    },
  ];

  return (
    <Fragment>
      <Router>
        <Routes>
          {homeRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.main} />
          ))}
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;

import { getAccessToken } from "../auth/auth";
import { json2csv } from "json-2-csv";
import { initFetchRequest } from "../utils/helperFunctions";
import { fetchData } from "../utils/helperFunctions";
// This function is used to download an individual patient's food log data
// I takes in patient CRAID as a integer and returns an object with the users food
// log data and first/last name
export const downloadIndiviudalPatientData = async (
  userCRAID,
  firstLastName
) => {
  var token = await getAccessToken();
  var logRequests = initFetchRequest("/api/patients/" + userCRAID, token);
  var recipeRequests = initFetchRequest(
    "/api/patients/recipe/" + userCRAID,
    token
  );
  try {
    var logData = await fetchData(logRequests);
    var recipeData = await fetchData(recipeRequests);
  } catch (err) {}
  var data = logData.concat(recipeData);

  const csv = json2csv(data);
  var downloadLink = document.createElement("a");
  var blob = new Blob(["\ufeff", csv]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${
    firstLastName.includes("undefined") ? "report" : firstLastName
  }.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// This function is used to get an individual patient's food log data
export const getIndiviudalPatientData = async (userCRAID, firstLastName) => {
  var token = await getAccessToken();
  var logRequests = initFetchRequest("/api/patients/" + userCRAID, token);
  var recipeRequests = initFetchRequest(
    "/api/patients/recipe/" + userCRAID,
    token
  );
  try {
    var logData = await fetchData(logRequests);
    var recipeData = await fetchData(recipeRequests);
  } catch (err) {}
  var data = logData.concat(recipeData);

  return data;
};

export const getAllPatients = async () => {
  var token = await getAccessToken();
  try {
    const request = initFetchRequest(
      "/api/Patients/getAllDoctorPatients",
      token
    );
    const response = await fetch(request);
    const patientsList = await response.json();
    if (patientsList) {
      return patientsList;
    }
    return [];
  } catch (err) {
    console.error(err);
  }
};

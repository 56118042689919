import React, { useState, useEffect } from "react";
import { checkTOS, hasTOSChecked } from "../../auth/auth";
import Loading from "../common/Loading";

const TermsAndConditions = ({ onAgree }) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleAgree = () => {
    checkTOS();
    if (onAgree) onAgree();
  };

  useEffect(() => {
    setIsLoading(true);
    const verityTOS = async () => {
      const agreed = await hasTOSChecked();
      setIsAgreed(agreed);
      setIsLoading(false);
      if (agreed) {
        onAgree();
      }
    };
    verityTOS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center">
          <p className="text-center mb-4 text-lg">
            Please read our Terms of Service agreement carefully. To continue
            using our service, check the box below to indicate your acceptance.
          </p>
          <iframe
            src="https://www.computingreapplied.com/utcad"
            title="Terms and Conditions"
            style={{ border: "2px solid #ddd", borderRadius: "10px" }}
            width="100%"
            height="100%"
            className="mb-3
          w-full h-[500px] sm:w-[450px] sm:h-[400px]
          md:w-[600px] md:h-[450px]
          lg:w-[800px] lg:h-[600px]
          xl:w-[1000px] xl:h-[700px]
        "></iframe>

          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isAgreed}
              onChange={handleAgree}
              className="mr-2 -mt-2"
            />
            <label>
              I certify that I am 18 years or older & I agree to the Terms and
              Conditions
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditions;

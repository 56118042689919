import { useEffect, useState } from "react";
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";
import CommonLoadingIndicator from "./CommonLoadingIndicator";
import { Symptom } from "../StudyDash/types";
import { Typography, Box } from "@mui/material";
import SymptomsSevereLeyend from "../../../../img/SymptomsSevereLeyend.png";

type TableProps = {
  data: Array<Symptom>;
};

const COLORS = [
  "#ffdeaa", // lightest
  "#ffc68d", // lighter
  "#ffae70", // light orange
  "#ff9553", // medium orange
  "#f97d3a", // darker orange
  "#f4865c", // darkest
];

// Transform data into a hierarchical structure for the Treemap
export function transformDataForTreemap(data: Array<Symptom>) {
  console.log({ data });
  const symptomCounts: Record<
    string,
    {
      count: number;
      patientIds: Array<{ id: number; name: string }>;
      displayName: string;
    }
  > = {};
  data.forEach((symptom) => {
    symptomCounts[symptom.name] = symptomCounts[symptom.name] || {
      count: 0,
      patientIds: [],
    };
    symptomCounts[symptom.name].count += 1;
    symptomCounts[symptom.name].patientIds.push({
      id: Number(symptom.userIdInt),
      name: symptom.displayName,
    });
  });
  return Object.entries(symptomCounts).map(
    ([name, { count, patientIds, displayName }]) => ({
      name,
      value: count,
      displayName,
      patientIds,
    })
  );
}

function SymptompsTreemap({ data }: TableProps) {
  const [treeData, setTreeData] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  useEffect(() => {
    if (!data) return;
    try {
      const transformedData = transformDataForTreemap(data);
      setTreeData(transformedData);
    } catch (error) {
      console.log(error);
    }
  }, [data]);
  const CustomizedContent = (props: any) => {
    const { root, depth, x, y, width, height, index, colors, name } = props;

    return (
      <g>
        <rect
          x={x + 1}
          y={y + 1}
          width={width - 2}
          height={height - 2}
          rx={8}
          style={{
            fill:
              depth < 2
                ? colors[Math.floor((index / root.children.length) * 6)]
                : "none",
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#000"
            stroke="none"
            style={{ fontWeight: "bold", color: "#000" }}
            color="black"
            dominantBaseline="middle"
            opacity="1"
            fontSize={16}>
            {name}
          </text>
        ) : null}
      </g>
    );
  };

  const CustomTooltip = ({ payload }: any) => {
    if (payload && payload.length) {
      const { value, patientIds } = payload[0].payload;
      return (
        <Box
          sx={{
            width: "351px",
            height: "162px",
            position: "relative",
            backgroundColor: "#f8f8f8",
            borderRadius: "7px",
            boxShadow: 1,
            border: "2px solid #f4865c",
          }}>
          <Box
            sx={{
              width: "100%",
              height: "27px",
              backgroundColor: "#f4865c",
              paddingTop: "3px",
              paddingLeft: "10px",
            }}>
            <Typography
              sx={{
                color: "white",
                fontSize: "9px",
                fontWeight: "900",
                fontFamily: "'Lato', sans-serif",
                textTransform: "uppercase",
              }}>
              Most recent loggers
            </Typography>
          </Box>
          <Box sx={{ padding: "10px" }}>
            <Box>
              <Typography
                sx={{
                  color: "#2f2f2f",
                  fontSize: "15px",
                  fontWeight: "bold",
                  fontFamily: "'Lato', sans-serif",
                  paddingBottom: "5px",
                }}>
                {Object.keys(patientIds).length} Patients, {value} Log Points
              </Typography>
            </Box>
            {Object.entries(
              patientIds.reduce(
                (acc: any, patient: { id: number; name: string }) => {
                  acc[patient.id] = (acc[patient.id] || 0) + 1;
                  return acc;
                },
                {}
              )
            ).map(([patientId, value]) => (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ paddingTop: "5px", paddingLeft: "10px", width: "70%" }}
                flexWrap={"nowrap"}>
                <Typography
                  sx={{
                    color: "#2f2f2f",
                    fontSize: "15px",
                    fontWeight: "bold",
                    fontFamily: "'Lato', sans-serif",
                    paddingRight: "15px",
                  }}>
                  {
                    patientIds.find(
                      (p: { id: number }) => p.id === Number(patientId)
                    )?.name
                  }
                </Typography>

                <Typography
                  component="span"
                  sx={{
                    color: "#e06b00",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "'Lato', sans-serif",
                  }}>
                  {String(value)}
                  <Typography
                    component="span"
                    sx={{
                      color: "#5b5b5b",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "'Lato', sans-serif",
                      paddingLeft: "5px",
                    }}>
                    {value === 1 ? "Log Point" : "Log Points"}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
    return null;
  };

  if (treeData && dataLoaded) {
    return (
      <>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: 3,
          }}>
          <Box display={"flex"}>
            <Typography variant="subtitle2" fontWeight="bold">
              Box size = Patient value
            </Typography>
          </Box>
          {/* Flex container for the legend */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Typography variant="body2">Least Severe</Typography>

            {/* Image for the color scale */}
            <img
              src={SymptomsSevereLeyend}
              alt="SymptomsSevereLeyend"
              width="41px"
              height="86px"
              style={{ margin: "0 16px" }} // Add margin to create spacing between the text and image
            />

            <Typography variant="body2">Most Severe</Typography>
          </Box>
        </Box>
        <Box>
          <ResponsiveContainer width="100%" height="100%">
            <Treemap
              data={treeData}
              dataKey="value"
              nameKey="name"
              stroke="#fff" // White stroke for separation
              fill="#fff" // Fill for boxes
              aspectRatio={4 / 2}
              isAnimationActive={false}
              content={<CustomizedContent colors={COLORS} />}
              type="flat">
              <Tooltip content={<CustomTooltip />} />
            </Treemap>
          </ResponsiveContainer>
        </Box>
      </>
    );
  } else {
    return <CommonLoadingIndicator />;
  }
}

export default SymptompsTreemap;

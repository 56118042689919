export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority:
        "https://computingreappliedb2c.b2clogin.com/ComputingReAppliedB2C.onmicrosoft.com/B2C_1_SignInSignUpConnector/", // This is your tenant info
    },
    changePassword: {
      authority:
        "https://computingreappliedb2c.b2clogin.com/ComputingReAppliedB2C.onmicrosoft.com/B2C_1_PasswordReset2024",
    },
  },
  authorityDomain: "computingreappliedb2c.b2clogin.com",
};

export const dev_b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority:
        "https://cradevelopmentdirectory.b2clogin.com/cradevelopmentdirectory.onmicrosoft.com/B2C_1_SignUpAndSignIn/", // This is your tenant info
    },
    changePassword: {
      authority:
        "https://cradevelopmentdirectory.b2clogin.com/cradevelopmentdirectory.onmicrosoft.com/B2C_1_ChangePassword",
    },
  },
  authorityDomain: "cradevelopmentdirectory.onmicrosoft.com",
};

import { API_PREFIX } from "../utils/globalConstants";

export const initFetchRequest = (url, accessToken) => {
  const myRequest = new Request(`${API_PREFIX}${url}`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + accessToken,
      //'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Allow-Origin": "https://portal.computingreapplied.com/",
      // 'Access-Control-Allow-Origin': 'https://localhost:5001/', //we keep this as localhost for testing, will switch out with computingreapplied.com at producyion
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      //'Access-Control-Allow-Headers': 'Origin, X- Requested - With, Content - Type, Accept',
    },
  });
  return myRequest;
};

export const fetchData = async (myRequest) => {
  return fetch(myRequest)
    .then((response) => {
      let dataJSON = response.json();
      return dataJSON;
    })
    .then((data) => {
      return data;
    })
    .catch((jqXHR, textStatus) => {
      if (process.env.NODE_ENV !== "test") {
        throw console.error(
          "Error calling the Web api:\n" + textStatus + "\n" + jqXHR
        );
      }
    });
};

import { Select } from "@mui/material";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";

export const CustomMUIDropDown = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    "position": "relative",
    "backgroundColor": "#FAFAFA",
    "color": "#5FBDD6",
    "border": "1px solid #5FBDD6",
    "boxShadow": "1px 3px 3px rgba(0, 0, 0, 0.5)",
    "fontSize": 14,
    "padding": 3,
    "paddingLeft": 10,
    "fontWeight": "bold",
    "fontFamily": [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 6,
      borderColor: "#5FBDD6",
    },
  },
  "& .MuiSelect-icon": {
    color: "#1c9dbf",
  },
}));

export const FormControlStyled = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const datePickerMUIStyles = {
  "width": "150px",
  "& .MuiInputBase-input": {
    textAlign: "center",
    fontSize: "14px",
    color: "#1c9dbf",
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    textTransform: "uppercase",
    padding: "6px",
  },
  "& .MuiSvgIcon-root": {
    color: "#1c9dbf", // Change the icon color to match the font color
  },
  "& .MuiInputBase-root": {
    borderRadius: "10px", // Rounded corners
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #999999", // Darker border and single border
    borderRadius: "10px", // Match the border radius
  },
};

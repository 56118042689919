import { useEffect, useMemo, useState } from "react";
import StudyInformationCard from "./components/StudyDash/StudyInformationCard";
import PatientsOverviewCard from "./components/StudyDash/PatientsOverviewCard";
import StudyGraphComponent from "./components/StudyDash/StudyGraphComponent";
import ViewPatientsModal from "./components/Modals/ViewPatientsModal";

import "./StudyDashboard.css";
import "../../overall.css";
import TitleBar from "../../components/common/TitleBar";
import DashBoardDropDown from "./DashBoardDropDown";
import InvitePatientModal from "../../components/invitePatientModal/InvitePatientModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewStudyModal from "../HomeDiary/Modals/NewStudyModal/NewStudyModal";
import { getAllPatients } from "../../API/PatientDirectoryCalls";

interface Study {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<Patient>;
  };
  studyID: Number;
}

type StudyDashProps = {
  patients: Array<Patient>;
  allStudyData: Study[];
  dashBoardStudyData: any;
};

type Patient = {
  $id: string;
  givenName: string;
  surname: string;
  userName: string;
  patientID: number;
};

function StudyDashboard(data: StudyDashProps) {
  const [showPatientsModal, setShowPatientsModal] = useState(false);
  const [study, setStudy] = useState<Study>(data.allStudyData[0]);
  const [studyIndex, setStudyIndex] = useState<number>(0);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [newStudyModalOpen, setNewStudyModalOpen] = useState(false);
  const [patients, setPatients] = useState<Array<Patient>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [
    showInviteModalWithoutAllPatients,
    setShowInviteModalWithoutAllPatients,
  ] = useState(false);

  const dropdown = useMemo(
    () => (
      <DashBoardDropDown
        setStudyIndex={setStudyIndex}
        setStudy={setStudy}
        study={study}
        studyIndex={studyIndex}
        studies={data.allStudyData}
      />
    ),
    [study, studyIndex, data.allStudyData]
  );
  const handlePostStudy = (study: any) => {
    // window.location.reload();
  };

  useEffect(() => {
    if (data.allStudyData.length <= 0 && isLoaded) {
      setIsLoaded(false);
      const loadPatients = async () => {
        const patientsList = await getAllPatients();
        setPatients(patientsList);
        setIsLoaded(true);
        setNewStudyModalOpen(true);
        toast.warning("Please add patients to your study");
      };
      loadPatients();
    } else {
      setIsLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaded ? (
        <NewStudyModal
          newStudyModalOpen={newStudyModalOpen}
          setNewStudyModalOpen={setNewStudyModalOpen}
          patients={patients}
          setIsLoaded={setIsLoaded}
          handlePostStudy={handlePostStudy}
        />
      ) : (
        <div className="parentContainer study-dashboard">
          <div className="mainContainer">
            {/* Rule in app.css */}
            {/*Send props to change title bar dependnig on page */}
            <TitleBar title={"Study Dashboard"} studyDropdown={dropdown} />
            {/*Send props to change title bar dependnig on page */}
            <div>
              <div className="studyInformation row">
                <StudyInformationCard
                  studyIndex={studyIndex}
                  studies={data.allStudyData}
                  setViewPatientsModalOpen={setShowPatientsModal}
                  studyInfo={data.dashBoardStudyData.studies}
                  studyId={study?.studyID}
                />
                <PatientsOverviewCard
                  studyIndex={studyIndex}
                  studies={data.allStudyData}
                  studyInfo={data.dashBoardStudyData.studies}
                  studyId={study?.studyID}
                  openViewAllPatients={() => setShowPatientsModal(true)}
                  openInviteModal={() =>
                    setShowInviteModalWithoutAllPatients(true)
                  }
                />
              </div>
              <StudyGraphComponent studyId={study?.studyID} />
            </div>
          </div>
          <ViewPatientsModal
            onClose={() => setShowPatientsModal(false)}
            show={showPatientsModal}
            study={data.allStudyData[studyIndex as number]}
            setShowInviteModal={() => {
              setShowInviteModal(true);
            }}
          />

          <InvitePatientModal
            show={showInviteModal || showInviteModalWithoutAllPatients}
            studies={data.allStudyData}
            studyData={data.dashBoardStudyData.studies}
            onShowNotificationSuccess={() => {
              toast("Email has been sent!");
            }}
            onClose={() => {
              setShowInviteModal(false);
              if (!showInviteModalWithoutAllPatients) {
                setShowPatientsModal(true);
              }
              setShowInviteModalWithoutAllPatients(false);
            }}
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
}
export default StudyDashboard;

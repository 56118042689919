import { useEffect } from "react";
import HomeNavBar from "../components/navigation/HomeNavBarResponsive";
import HomePageIllustration from "../img/homepage-illustration-responsive-view.svg";
import ScanBarcode from "../img/ScanBarcode.svg";
import Download from "../img/download.svg";
import Symptoms from "../img/symptoms.svg";
import CaseStudy from "../img/caseStudy.svg";
import Macbook from "../img/MacBookPro19.png";
import CaseStudySmall from "../img/caseStudy-small.svg";
import Share from "../img/share.svg";
import "./HomePageResponsive.css";
import Feature from "../components/homepage/Feature";
import { useMediaQuery } from "@mui/material";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientButton from "../components/common/GradientButton";

type Props = {
  signin: () => void;
};

function HomePage({ signin }: Props) {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("auto") === "start") {
      signin();
    }
  }, [signin]);

  const isMobile = useMediaQuery("(max-width: 768px)");

  var root = document.getElementById("root");
  if (root) {
    root.style.gridTemplateColumns = "1fr";
  }
  return (
    <>
      <section id="homepage-container">
        <HomeNavBar signin={signin} />
        <section className="home-page-main">
          <section id="homepage-content-container">
            <section id="homepage-text-container">
              <h1 className="homepage-title text-3xl">
                Nutrition and Symptom Research Tool
              </h1>
              <ul id="homepage-feature-list" className="mb-4 sm:mb-5">
                <li>Replace 24 hour recall method</li>
                <li>Collect nutrition and symptom data with ease</li>
                <li>Automatic data processing</li>
              </ul>
              <GradientButton
                onClick={signin}
                text="Sign Up for Free"
                additionalClasses="h-8 w-48 sign-up-button"
              />
            </section>
            <section id="illustration-container">
              <img src={HomePageIllustration} alt="" />
            </section>
          </section>
          <section
            id="section-two"
            className="flex flex-column gap-y-20 mt-28 align-center text-center">
            <div>
              <div className="hidden sm:block w-full sm:w-1/2 mt-0 mb-0 ml-auto mr-auto p-2">
                <h1 className="homepage-title text-3xl sm:text-4xl m-0">
                  myMedDiary app
                </h1>
                <h3 className="sub-title text-2xl">
                  An easy to use dietary research data
                </h3>
                <h3 className="sub-title text-2xl">
                  collection app. Get clean data in seconds.
                </h3>
              </div>
              <div className="sm:hidden w-full sm:w-1/2 mt-0 mb-0 ml-auto mr-auto p-2">
                <h1 className="homepage-title text-3xl sm:text-4xl m-0">
                  myMedDiary app
                </h1>

                <h3 className="sub-title text-2xl">
                  An easy to use dietary research data collection app. Get clean
                  data in seconds.
                </h3>
              </div>
            </div>
            <section className="flex flex-col sm:flex-row items-center justify-evenly gap-10 sm:gap- sm:gap-auto">
              <section className="barcode-scanner w-1/2 sm:flex sm:justify-end">
                <img src={ScanBarcode} alt="" />
              </section>
              <section
                id="features-container"
                className="flex flex-column justify-evenly gap-y-16 sm:gap-y-24 h-full items-center pr-4 pl-4">
                <Feature
                  number="1"
                  image={Download}
                  title="Download & Sign Up"
                  description="Research particpants download myMedDiary from the IOS or Android app store and sign up easily through the app or website"
                />
                <Feature
                  number="2"
                  image={Symptoms}
                  title="Log Symptoms & Food Intake"
                  description="Symptoms and food are logged by participants through the mobile app"
                />
                <Feature
                  number="3"
                  image={Share}
                  title="View & Share Data"
                  description="Researchers / Admins of research study can view the data in real-time and instantly share it globally with other institutions or researchers"
                />
              </section>
            </section>
          </section>
          <section
            id="section-three"
            className="flex flex-column gap-y-20 mt-28 align-center text-center">
            <div>
              <div className="w-full sm:w-1/2 mt-0 mb-0 ml-auto mr-auto p-2">
                <h1 className="homepage-title text-3xl sm:text-4xl m-0">
                  Case Study:
                </h1>
                <h1 className="homepage-title text-3xl sm:text-4xl mb-8">
                  Seattle Children's Hospital
                </h1>
                <h3 className="sub-title text-2xl">
                  Compared to 24 hour dietary recall methods, customized
                  myMedDiary can record nutritional data with a higher precision
                </h3>
              </div>
            </div>
            <section className="flex flex-col sm:flex-row items-center justify-evenly gap-10 sm:gap- sm:gap-auto">
              {isMobile ? (
                <img src={CaseStudySmall} alt="" />
              ) : (
                <img src={CaseStudy} className="w-[1131px]" alt="" />
              )}
            </section>
          </section>
          <section
            id="section-four"
            className="flex flex-column mb-14 sm:min-h-full gap-y-20 mt-36">
            <section className="flex flex-col sm:flex-row items-center justify-evenly sm:justify-center gap-10 sm:gap-40">
              <section className="flex flex-column justify-end h-full items-center ">
                <div className="sm:flex flex-column justify-end h-full items-center hidden ">
                  <h1 className="homepage-title ">Researchers</h1>
                  <h3 className="sub-title text-2xl">
                    Ready to try myMedDiary
                  </h3>
                  <h3 className="sub-title text-2xl mb-4">for free today?</h3>
                  <GradientButton
                    onClick={signin}
                    text="Sign Up Now"
                    additionalClasses="h-8 w-48">
                    <FontAwesomeIcon
                      className="pl-2 align-middle"
                      icon={faArrowRight}
                    />
                  </GradientButton>
                </div>
              </section>
              <section className="sm:flex sm:justify-start">
                <img src={Macbook} alt="" />
              </section>
              <section className="flex flex-column justify-evenly h-full items-center sm:hidden">
                <h1 className="homepage-title">Researchers</h1>
                <h3 className="sub-title text-2xl">Ready to try myMedDiary</h3>
                <h3 className="sub-title text-2xl mb-4">for free today?</h3>

                <GradientButton
                  onClick={signin}
                  text="Sign Up Now"
                  additionalClasses="h-8 w-48">
                  <FontAwesomeIcon
                    className="pl-2 align-middle"
                    icon={faArrowRight}
                  />
                </GradientButton>
              </section>
            </section>
          </section>
        </section>

        <footer className="relative sm:mt-48">
          <div className="text-center bg-white p-2 inset-x-0 top-0">
            <p>
              © 2024 by{" "}
              <a
                href="https://www.computingreapplied.com"
                target="_blank"
                rel="noreferrer">
                Computing ReApplied
              </a>
            </p>
            <p>
              When needed, nutritional data provided by{" "}
              <a href="https://www.nutritionix.com/">NutritionIX.com</a>.
              Otherwise all data is from{" "}
              <a
                href="https://www.computingreapplied.com"
                target="_blank"
                rel="noreferrer">
                C-RA
              </a>{" "}
              database
            </p>
          </div>
        </footer>
      </section>
    </>
  );
}

export default HomePage;

import FormControl from "@mui/material/FormControl";
import { Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

interface Study {
  abstract: String;
  name: String;
  patients: {
    $id: String;
    $values: Array<any>;
  };
  studyID: String;
}

type Props = {
  onChangeFunction: (e: any) => void;
  value: any;
  studies: Study[];
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiSelect-select": {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "8px 14px",
    fontSize: 16,
  },
}));

function StudyDropDown({ studies, value, onChangeFunction }: Props) {
  return (
    <div className={`study-drop-down w-full`}>
      <FormControl sx={{ width: "100%" }}>
        <Select
          fullWidth={true}
          labelId="study-select"
          id="demo-customized-select"
          value={value}
          onChange={onChangeFunction}
          input={<BootstrapInput />}>
          {studies.map((study) => {
            return (
              <MenuItem
                value={study.studyID as string}
                key={study.studyID as string}>
                {study.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default StudyDropDown;

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { roundDecimal } from "../../../../utils/helperFunctions";
import CommonLoadingIndicator from "./CommonLoadingIndicator";
type TableProps = {
  data: Array<Object>;
};

const DATAGRID_INITIAL_STATE = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
};

export function transformDataToRows(data: Array<Object>) {
  return data.map((value: any) => {
    return {
      id: value.$id,
      date: value.consumedDate.split("T")[0],
      calories: roundDecimal(
        value.energyKcal || (value.energyKj > 0 ? value.eneryKj / 4.184 : "0")
      ),
    };
  });
}
function CaloriesTable({ data }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();

  const columns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "calories", headerName: "Calories", width: 200 },
  ];

  useEffect(() => {
    try {
      if (!data) return;
      const transformedData = transformDataToRows(data);

      setTableRows(transformedData);
    } catch (error) {
      console.error(error);
    }
  }, [data]);

  if (tableRows && data) {
    return (
      <DataGrid
        rows={tableRows}
        columns={columns}
        initialState={DATAGRID_INITIAL_STATE}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[10, 25, 50, 100]}
      />
    );
  } else {
    return <CommonLoadingIndicator />;
  }
}

export default CaloriesTable;

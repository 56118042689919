import React from "react";
import HomeNavBar from "../components/navigation/HomeNavBarResponsive";
import { Fade } from "react-slideshow-image";
import "./OnboardingPage.css";
import PhonePhoto1 from "../img/iphone1.png";
import PhonePhoto2 from "../img/android1.png";
import PhonePhoto3 from "../img/iphone2.png";
import PhonePhoto4 from "../img/android2.png";
import AppleIcon from "../img/apple.png";
import LoggingIcon from "../img/logging.png";
import StethescopeIcon from "../img/stethescope.png";
import CrossIcon from "../img/internal.png";
import LogInIcon from "../img/profile.png";
import EnrollmentIcon from "../img/enrollment.png";
import PatientDataIcon from "../img/patientdata.png";

type Props = {
  signin: () => void;
};

function OnboardingPage({ signin }: Props) {
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
    onChange: (oldIndex: number, newIndex: number) => {
      console.log(`fade transition from ${oldIndex} to ${newIndex}`);
    },
  };

  // Get rid of grid display
  document.getElementById("root")!.style.display = "block";
  return (
    <div>
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{
            background: "linear-gradient(90deg, #f5f9ff 35%, #7ecfe4 100%)",
            color: "hsl(0, 0%, 10%)",
            textAlign: "center",
            height: "95vh",
          }}>
          <HomeNavBar signin={signin} />
          <h2
            style={{
              fontSize: "6vw",
              fontWeight: 600,
              paddingTop: "5vh",
              display: "inline",
            }}
            className="pagetitle">
            myMedDiary
          </h2>
          <h2
            style={{
              fontSize: "2vw",
              fontWeight: 600,
              paddingTop: "5vh",
              display: "inline",
              verticalAlign: "top",
            }}
            className="pagetitle">
            &reg;
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "35%",
            }}>
            <h2
              style={{
                color: "gray",
                fontWeight: 300,
                paddingRight: "10px",
                fontSize: "1.65vw",
              }}
              className="pagetitle">
              powered by
            </h2>
            <h1
              style={{
                color: "#2157DE",
                fontWeight: "bold",
                fontSize: "3.25vw",
              }}
              className="cra">
              C-RA
            </h1>
            <div style={{color: "blue", fontWeight: "bold", fontSize: "1.25vw" }}
              className="pagetitle">TM</div>
          </div>
          <p
            style={{
              margin: "2rem auto 4rem",
              width: "50vw",
            }}>
            Your personal medical diary! Take control of your health and track
            your symptoms for more efficient and accurate diagnoses, prevention,
            and treatment of diseases and disorders!
          </p>
          <div
            className="slideshow"
            style={{
              background: "linear-gradient(90deg, #f5f9ff 35%, #7ecfe4 100%)",
            }}>
            <Fade {...fadeProperties}>
              <div className="each-fade">
                <div>
                  <div className="image-container">
                    <img
                      style={{ width: "25%", height: "80%" }}
                      src={PhonePhoto1}
                      alt="app dashboard on iphone"
                    />
                  </div>
                </div>
              </div>
              <div className="each-fade">
                <div className="image-container">
                  <img
                    style={{ width: "25%" }}
                    src={PhonePhoto2}
                    alt="app dashboard on android"
                  />
                </div>
              </div>
              <div className="each-fade">
                <div className="image-container">
                  <img
                    style={{ width: "25%" }}
                    src={PhonePhoto3}
                    alt="app symptom logging page on iphone"
                  />
                </div>
              </div>
              <div className="each-fade">
                <div className="image-container">
                  <img
                    style={{ width: "25%" }}
                    src={PhonePhoto4}
                    alt="app symptom logging page on android"
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div>
          <h3 id="howitworks">How it Works</h3>
          <div className="line"></div>
          <div className="contentcontainer">
            <div className="descriptioncontainer">
              <h4>Download & Sign Up</h4>
              <p>
                Download myMedDiary from the IOS or Android app store and sign
                up easily through the app or website
              </p>
            </div>
            <div className="iconcontainer">
              <img
                className="imgshadow"
                src={AppleIcon}
                style={{
                  height: "16vw",
                  padding: "4vw",
                  backgroundColor: "white",
                }}
                alt="apple icon"
              />
              <span
                className="nums"
                style={{
                  position: "relative",
                  top: "-22vw",
                  left: "-4vw",
                  fontWeight: "bolder",
                  fontSize: "8vw",
                }}>
                01
              </span>
            </div>
          </div>
          <div className="line"></div>
          <div className="contentcontainer">
            <div className="iconcontainer">
              <img
                className="imgshadow"
                src={LoggingIcon}
                style={{
                  height: "16vw",
                  padding: "4vw",
                  backgroundColor: "white",
                }}
                alt="logging icon"
              />
              <span
                className="nums"
                style={{
                  position: "relative",
                  top: "-22vw",
                  left: "-4vw",
                  fontWeight: "bolder",
                  fontSize: "8vw",
                }}>
                02
              </span>
            </div>
            <div className="descriptioncontainer">
              <h4>Log Symptoms</h4>
              <p>
                Log any symptoms you may be experiencing throughout the day
                after eating
              </p>
            </div>
          </div>
          <div className="line"></div>
          <div className="contentcontainer">
            <div className="descriptioncontainer">
              <h4>Data sent to Doctor</h4>
              <p>
                All this information is instantly shared with your doctor who
                can then properly evaluate this information to provide one with
                a proper diagnosis, treatment and plan for diet and lifestyle
                changes if needed
              </p>
            </div>
            <div className="iconcontainer">
              <img
                className="imgshadow"
                src={StethescopeIcon}
                style={{
                  height: "16vw",
                  padding: "4vw",
                  backgroundColor: "white",
                }}
                alt="stethescope icon"
              />
              <span className="nums">03</span>
            </div>
          </div>
        </div>
        <span id="onboarding">ONBOARDING</span>
        <div className="parallax"></div>
        <div>
          <div className="colorboxes">
            <div className="colorbox" style={{ backgroundColor: "#7ECFE4" }}>
              <img src={CrossIcon} alt="cross icon"></img>
              <h4 style={{ color: "white", marginBottom: "3.5vh" }}>
                Internal
              </h4>
              <p style={{ marginRight: "4vw", marginLeft: "4vw" }}>
                On-boarding for doctors happens internally through the hospital,
                they are provided with a temporary password with their
                associated email
              </p>
            </div>
            <div className="colorbox">
              <img src={LogInIcon} alt="log in icon"></img>
              <h4 style={{ color: "#F27E25", marginBottom: "3.5vh" }}>Login</h4>
              <p style={{ marginRight: "4vw", marginLeft: "4vw" }}>
                Once provided with their temporary password, they are able to
                access the online portal and login with these credentials
              </p>
            </div>
            <div className="colorbox" style={{ backgroundColor: "#7ECFE4" }}>
              <img
                className="warped"
                src={EnrollmentIcon}
                alt="enrollment icon"></img>
              <h4 style={{ color: "white", marginBottom: "3.5vh" }}>
                Enrollment
              </h4>
              <p style={{ marginRight: "4vw", marginLeft: "4vw" }}>
                Once logged in doctors are able to enroll patients and provide
                them with an enrollment key which can be used to login to the
                mobile application
              </p>
            </div>
            <div className="colorbox">
              <img
                className="warped"
                src={PatientDataIcon}
                alt="patient data icon"></img>
              <h4 style={{ color: "#F27E25", marginBottom: "3.5vh" }}>
                Patient Data
              </h4>
              <p style={{ marginRight: "4vw", marginLeft: "4vw" }}>
                Once patients are enrolled and actively using the application
                doctors are able to access patients food and symptom data on the
                online dashboard
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            opacity: 0.5,
            textAlign: "center",
            backgroundColor: "#F27E25",
          }}>
          <p id="footer-text">© 2020 by Computing ReApplied</p>
        </div>
      </div>
    </div>
  );
}

export default OnboardingPage;

import { Component, ChangeEvent } from "react";
import VisualizationDropDown from "../VisualizationDropDown";
import GenderDropDown from "../GenderDropDown";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { getSignedInUserId } from "../../../../auth/auth";
import { FetchInfo } from "../../../../components/common/types/FetchInfo";
import GraphTypeDropDown from "../GraphTypeDropDown";
import MacroCheckBox from "../MacroCheckBox";
import { MacroChecks } from "../../../../components/common/types/MacroChecks";
import FileTypeDownloadSelector from "../FileTypeDownloadSelector";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { datePickerMUIStyles } from "../customMUIStyles";
import SymtomCategoryDropDown from "../SymtomCategoryDropDown";
import SymptomsSelector from "../SymptomsSelector";
import allSymptoms from "../../../../utils/globalConstants";
import { Checkbox, FormControlLabel } from "@mui/material";

interface GraphDisplayProps {
  isStudyDash: boolean;
  api_data: any[]; // Add this line
  setData: (data: any) => void;
  setLoading: (loading: boolean) => void;
  setFetchInfo: (info: FetchInfo | undefined) => void;
  setVisualization: (visualization: string) => void;
  handleDownloadData: () => void;
  handleLoadData: (userId: string) => void;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  handleGraphTypeChange: (value: string) => void;
  handleSymptomCategoryChange: (value: string) => void;
  handleSymptomSelectedChange: (value: string[]) => void;
  handleVisualizationChange: (value: string) => void;
  setMacroChecks: (checks: MacroChecks) => void;
  macroChecks: MacroChecks;
  graphType: string;
  symptomCategory: string;
  symptomsSelected: string[];
  rawDataChecked: boolean;
  onRawDataChange: () => void;
  visualization: string;
  startDate: Date;
  endDate: Date;
}

interface GraphDisplayState {
  visualization: string;
  graphType: string;
  symptomCategory: string;
  symptomsSelected: string[];
  vitamin: string;
  macro: string;
  fatsChecked: boolean;
  carbsChecked: boolean;
  proteinsChecked: boolean;
  caloriesChecked: boolean;
  gender: string;
  minAge: number;
  maxAge: number;
  selectedDateOne: Date;
  selectedDateTwo: Date;
  isGraphDataLoading: boolean;
  isGraphButtonClicked: boolean;
  api_data: any | null;
  vitaminData: any | null;
  mineralData: any | null;
  loggingData: any | null;
  macroData: any | null;
  severityData: any | null;
  isStudyDash: boolean;
  fileType: string;
  isDownloadSectionExpanded: boolean;
}

class StudyGraphDisplay extends Component<
  GraphDisplayProps,
  GraphDisplayState
> {
  constructor(props: GraphDisplayProps) {
    super(props);
    this.state = {
      visualization: "macronutrients",
      graphType: "table",
      symptomCategory: "all",
      symptomsSelected: [],
      vitamin: "",
      macro: "",
      fatsChecked: true,
      carbsChecked: true,
      proteinsChecked: true,
      caloriesChecked: true,
      gender: "",
      minAge: 0,
      maxAge: 120,
      selectedDateOne: new Date(),
      selectedDateTwo: new Date(),
      isGraphDataLoading: false,
      isGraphButtonClicked: false,
      api_data: null,
      vitaminData: null,
      mineralData: null,
      loggingData: null,
      macroData: null,
      severityData: null,
      isStudyDash: props.isStudyDash,
      fileType: "csv",
      isDownloadSectionExpanded: false,
    };
  }

  componentDidMount() {
    //console.log(this.props.isStudyDash);
  }

  componentDidUpdate() {
    // console.log(this.props.isStudyDash);
  }

  componentWillUnmount() {}

  onChangeMacro = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.checked } as any, () => {
      this.props.setMacroChecks({
        fatsChecked: this.state.fatsChecked,
        carbsChecked: this.state.carbsChecked,
        proteinsChecked: this.state.proteinsChecked,
        caloriesChecked: this.state.caloriesChecked,
      });
    });
  };

  onChangeVisualization = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.setData([]);
    this.props.setFetchInfo(undefined);
    // if (["micronutrients", "symptoms"].includes(e.target.value)) {
    this.props.handleGraphTypeChange("table");
    // }
    this.props.handleVisualizationChange(e.target.value);
  };

  onChangeSymptomCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.handleSymptomCategoryChange(e.target.value);
    const allSymptoms = this.getSymptomsOptions();
    this.props.handleSymptomSelectedChange([]); // Uncheck all
    this.props.handleSymptomSelectedChange(
      allSymptoms[e.target.value as keyof typeof allSymptoms].map(
        (option) => option.value
      )
    ); // Select all
  };

  onChangeSymptomSelected = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const allSymptoms = this.getSymptomsOptions();

    if (value.includes("all")) {
      if (this.props.symptomsSelected.includes("all")) {
        this.props.handleSymptomSelectedChange([]); // Uncheck all
      } else {
        this.props.handleSymptomSelectedChange(
          allSymptoms[
            this.props.symptomCategory as keyof typeof allSymptoms
          ].map((option) => option.value)
        ); // Select all
      }
    } else {
      this.props.handleSymptomSelectedChange(
        Array.isArray(value) ? value : [value]
      );
    }
  };

  onChangeGraphType = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.handleGraphTypeChange(e.target.value);
  };

  onChangeVitamin = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ vitamin: e.target.value });
  };

  onChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ gender: e.target.value });
  };

  onChangeAgeOne = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  onChangeAgeTwo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) } as any);
  };

  handleDateOneChange = (date: Date | null) => {
    if (date) {
      this.props.handleStartDateChange(date);
    }
  };

  handleDateTwoChange = (date: Date | null) => {
    if (date) {
      this.props.handleEndDateChange(date);
    }
  };

  getUserId = async () => {
    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      var userIDfromcall = await getSignedInUserId();
      if (userIDfromcall === null) {
        console.error("Failed to fetch user ID");
        alert("Failed to fetch the user ID. Please try again.");
        return;
      } else {
        userID = userIDfromcall;
      }
    }
    return userID;
  };

  getFechInfo = async (userId: number): Promise<FetchInfo | undefined> => {
    return {
      visualization: this.props.visualization,
      graphType: this.props.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.props.startDate,
      selectedDateTwo: this.props.endDate,
      userID: userId.toString(),
    };
  };

  handleSubmitDataClick = async () => {
    this.props.setLoading(true);
    let userID = 0;
    if (this.props.visualization !== "symptoms") {
      userID = (await this.getUserId()) || 0;
      if (!userID) {
        return;
      }
    }
    this.props.handleLoadData(userID.toString());
    // this.setState({
    //   isGraphDataLoading: true,
    //   isGraphButtonClicked: true,
    // });
    const fetchInfo = await this.getFechInfo(userID);
    if (!fetchInfo) {
      this.props.setFetchInfo(undefined);
      return;
    }
    this.props.setFetchInfo(fetchInfo);
  };

  handleExportDataClick = () => {
    this.props.setData([]);
    this.setState({
      isGraphDataLoading: true,
      isGraphButtonClicked: true,
    });

    let userID = parseInt(
      window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
    );
    if (isNaN(userID)) {
      console.error("Failed to fetch user ID");
      alert("Failed to fetch the user ID. Please try again.");
      return;
    }

    const fetchInfo: FetchInfo = {
      visualization: this.state.visualization,
      graphType: this.state.graphType,
      gender: this.state.gender,
      minAge: this.state.minAge,
      maxAge: this.state.maxAge,
      selectedDateOne: this.state.selectedDateOne,
      selectedDateTwo: this.state.selectedDateTwo,
      userID: userID.toString(),
      export: true,
    };
    this.props.setFetchInfo(fetchInfo);
  };

  renderDisplay() {
    // Placeholder for future content
    return null;
  }

  renderAgeGender() {
    if (this.state.isStudyDash) {
      return (
        <Box>
          <label>Gender:</label>
          <GenderDropDown onChangeFunction={this.onChangeGender} />
          <label>Age Range:</label>
          <Box className="row">
            <input
              className="age-input"
              onChange={this.onChangeAgeOne}
              type="number"
              id="min-age"
              name="minAge"
            />
            <p className="separate">to</p>
            <input
              className="age-input"
              onChange={this.onChangeAgeTwo}
              type="number"
              id="max-age"
              name="maxAge"
            />
          </Box>
        </Box>
      );
    }
    return <Box></Box>;
  }

  onChangeFileType = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ fileType: e.target.value });
  };
  toggleDownloadSection = () => {
    this.setState((prevState) => ({
      isDownloadSectionExpanded: !prevState.isDownloadSectionExpanded,
    }));
  };

  convertToValidSelectOptions = (options: string[]) => {
    return options.map((option) => ({
      value: option.toLowerCase().replace(/\s/g, ""),
      text: option,
    }));
  };

  getSymptomsOptions = () => {
    const coldOptions = this.convertToValidSelectOptions(allSymptoms.cold);
    const painOptions = this.convertToValidSelectOptions(allSymptoms.pain);
    const stomachOptions = this.convertToValidSelectOptions(
      allSymptoms.stomach
    );
    const allSymptomsOptions = {
      all: [...coldOptions, ...painOptions, ...stomachOptions],
      cold: coldOptions,
      pain: painOptions,
      stomach: stomachOptions,
    };
    return allSymptomsOptions;
  };
  render() {
    const { isDownloadSectionExpanded } = this.state;
    const allSymptomsOptions = this.getSymptomsOptions();

    return (
      <Box className="box-container study-graph-display">
        <form className="formContainer">
          <Box className="card-title-text">Data Visualization</Box>
          <Box className="pr-2 pl-2 pt-1">
            <Box className="body-text body-text-size">Data group:</Box>
            <VisualizationDropDown
              value={this.props.visualization}
              onChangeFunction={this.onChangeVisualization}
              options={[
                "symptoms",
                "macronutrients",
                "micronutrients",
                "calories",
              ]}
            />
            {this.props.visualization === "macronutrients" &&
            this.props.graphType !== "table" ? (
              <>
                <MacroCheckBox
                  onChangeFunction={this.onChangeMacro}
                  macroChecks={this.props.macroChecks}
                />
              </>
            ) : null}
            {this.props.visualization === "symptoms" ? (
              <>
                <Box className="body-text body-text-size mt-1">
                  Symptom Category:
                </Box>
                <SymtomCategoryDropDown
                  value={this.props.symptomCategory}
                  disabled={false}
                  onChangeFunction={this.onChangeSymptomCategory}
                  options={[
                    { value: "all", text: "All" },
                    { value: "cold", text: "Cold/Flu" },
                    { value: "stomach", text: "Stomach Bug" },
                    { value: "pain", text: "Pain" },
                  ]}
                />
                <SymptomsSelector
                  value={this.props.symptomsSelected}
                  disabled={false}
                  onChangeFunction={this.onChangeSymptomSelected}
                  options={allSymptomsOptions}
                  categorySelected={this.props.symptomCategory}
                />
              </>
            ) : null}
            <Box className="body-text body-text-size mt-1">Graph Type:</Box>
            <GraphTypeDropDown
              value={this.props.graphType}
              disabled={this.props.visualization === "micronutrients"}
              onChangeFunction={this.onChangeGraphType}
              options={
                this.props.visualization === "symptoms"
                  ? [
                      { value: "table", text: "Table" },
                      { value: "treemap", text: "Treemap" },
                    ]
                  : [
                      { value: "table", text: "Table" },
                      { value: "line", text: "Line Chart" },
                      { value: "bar", text: "Bar Chart" },
                    ]
              }
            />

            <Box>
              {this.renderAgeGender()}
              <Box className="body-text body-text-size mt-1 mb-1">
                Date Range:
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="MM/dd/yyyy"
                    views={["year", "month", "day"]}
                    onChange={this.handleDateOneChange}
                    defaultValue={new Date()}
                    sx={datePickerMUIStyles}
                  />
                  <p className="separate-date body-text body-text-size">to</p>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    format="MM/dd/yyyy"
                    views={["year", "month", "day"]}
                    onChange={this.handleDateTwoChange}
                    defaultValue={new Date()}
                    sx={datePickerMUIStyles}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "left",
                  alignItems: "center",
                }}
                className="mt-4">
                <Box
                  sx={{
                    "display": "flex",
                    "& > button": {
                      "backgroundColor": "#5fbdd6",
                      "color": "white",
                      "&:hover": {
                        color: "#5fbdd6",
                      },
                      "borderColor": "white",
                      "borderRadius": "10px",
                    },
                  }}>
                  <LoadingButton
                    data-testid="load-table-button"
                    sx={{ width: "150px" }}
                    size="large"
                    onClick={this.handleSubmitDataClick}
                    loading={false}
                    variant="outlined">
                    Load Table
                  </LoadingButton>
                </Box>
              </Box>
              <Box>
                <Box className="mt-4">
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={this.toggleDownloadSection}>
                    <Box className="h-[17px] text-[#002a59] text-base font-bold font-['Lato'] uppercase leading-[21px]">
                      Download Data
                    </Box>
                    {isDownloadSectionExpanded ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </Box>
                  {isDownloadSectionExpanded && (
                    <Box className="download-section">
                      <Box className="h-2 relative origin-top-left rotate-90 opacity-80" />

                      {this.props.visualization === "symptoms" ? (
                        <>
                          <Box className="text-[#999999] text-xs font-semibold">
                            Save{" "}
                            {this.props.rawDataChecked
                              ? "raw numerical data"
                              : "original data being displayed."}
                          </Box>
                          <FormControlLabel
                            className="text-[#999999] text-xs font-semibold"
                            control={
                              <Checkbox
                                checked={this.props.rawDataChecked}
                                onChange={this.props.onRawDataChange}
                                name="rawDataChecked"
                              />
                            }
                            label="Raw Data"
                          />
                        </>
                      ) : (
                        <Box className="text-[#999999] text-xs font-semibold">
                          Save raw data being displayed.
                        </Box>
                      )}
                      <FileTypeDownloadSelector
                        options={[
                          { value: "none", text: "Choose File Type" },
                          { value: "csv", text: "CSV" },
                        ]}
                        disabled={false}
                        onChangeFunction={this.onChangeFileType}
                        value={this.state.fileType}
                        handleDownloadData={this.props.handleDownloadData}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    );
  }
}

export default StudyGraphDisplay;

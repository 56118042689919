import { Modal, TextField, Button } from "@mui/material";
import "./InvitePatientModal.css";

import { useEffect, useRef, useState } from "react";

import { ReactComponent as BulletPoint } from "../../img/bullet-point.svg";
import StudyDropDown from "./StudyDropDown";
import { sendDoctorToPatientEmail } from "../../API/HomeDiaryCalls";

interface Props {
  show: boolean;
  onClose: () => void;
  studies: any[];
  studyData: any;
  onShowNotificationSuccess?: () => void;
}

const InvitePatientModal = ({
  show,
  onClose,
  studies,
  studyData,
  onShowNotificationSuccess,
}: Props) => {
  const defaultStudySelected =
    studies && studies[0] ? studies[0].studyID : null;
  const [patientName, setPatientName] = useState("");
  const [selectedStudy, setSelectedStudy] = useState(defaultStudySelected);
  const [email, setEmail] = useState("");
  const [invitationText, setInvitationText] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [studyName, setStudyName] = useState("");

  const inviteLink = useRef<HTMLAnchorElement>(null);

  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    if (email.length === 0 || invitationText.length === 0) {
      return;
    }
    const emailBody = invitationText;
    const subject = `Invitation to ${studyName}`;

    sendDoctorToPatientEmail(email, subject, emailBody);
    if (onShowNotificationSuccess) {
      onShowNotificationSuccess();
    }
    onClose();
  };

  useEffect(() => {
    if (isEmailValid(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (selectedStudy) {
      const inviteCode = studyData[selectedStudy]?.studyEnrollmentCode;
      setInviteCode(inviteCode);
      setStudyName(
        studies.find((study) => (study.studyID as string) === selectedStudy)
          ?.name
      );
    }
  }, [selectedStudy, studyData, studies]);

  useEffect(() => {
    const text = `Hello ${patientName},\nYou are invited to ${studyName}.\nYour invite code is (${inviteCode}), please download the app from one of the links below:\n<a href="https://apps.apple.com/us/app/mymeddiary/id1506742164">Apple Store</a>\n<a href="https://play.google.com/store/apps/details?id=com.ComputingReApplied.myMedDiary">Google PlayStore</a>\nPlease sign up through the app and put in the invite code.`;
    setInvitationText(text);
  }, [patientName, studyName, inviteCode]);

  return (
    <>
      <Modal
        open={show}
        onClose={onClose}
        aria-labelledby="mobile-warning"
        aria-describedby="mobile-warning-description">
        <div className="invite-patient-modal box-container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-14 w-1/3 h-fit">
          <h1 className="font-bold text-cyan-500 text-center">
            NEW PATIENT INVITATION
          </h1>
          <div className="flex flex-column w-full gap-3 mt-10">
            <div className="w-full flex justify-between items-center">
              <h1 className="w-40">Patient Name</h1>
              <TextField
                fullWidth
                size="small"
                id="patientName"
                variant="outlined"
                onChange={(e) => setPatientName(e.target.value)}>
                {patientName}
              </TextField>
            </div>
            <div className="w-full flex justify-between items-center">
              <h1 className="w-40">Assign Study</h1>
              <StudyDropDown
                value={selectedStudy}
                studies={studies}
                onChangeFunction={(e: any) => setSelectedStudy(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-between items-center">
              <h1 className="w-40">E-mail Address</h1>
              <div className="w-full">
                <TextField
                  fullWidth
                  size="small"
                  id="emailAddress"
                  variant="outlined"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!validEmail ? (
                  <span className="text-red-500 text-xs">
                    Please enter a valid email
                  </span>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <TextField
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                id="invitationText"
                value={invitationText}
                onChange={(e) => setInvitationText(e.target.value)}
                multiline={true}
                variant="outlined"
              />
            </div>
          </div>
          <span className="mt-10 flex flex-row-reverse">
            <a ref={inviteLink} className="hidden" href={"mailto:"}>
              Mailto
            </a>
            <Button
              onClick={handleSubmit}
              disabled={!validEmail}
              variant="contained"
              sx={{
                color: "white",
                background: "#0195BC",
                textTransform: "none",
              }}>
              Send <BulletPoint />
            </Button>
          </span>
        </div>
      </Modal>
    </>
  );
};

export default InvitePatientModal;

import TitleBar from "../../components/common/TitleBar";
import PatientTable from "./PatientTable";
// import FilterSortByDropDown from './PatientDisplays/FilterSortByDropDown';
// import FilterStatusDropDown from './PatientDisplays/FilterStatusDropDown';
// import PatientDirectoryToggle from './PatientDisplays/PatientDirectoryToggle';

// import PatientCard from './PatientDisplays/PatientCard';
import "./PatientDirectory.css"

type DirectoryProps = {
  patients: Array<any>;
};

function PatientDirectory(patientData: DirectoryProps) {
  return (
    <div className="mainContainer patientDirectory">
      <TitleBar title="Patient Directory" />
      <div className="patientTableContainer">
        <PatientTable data={patientData.patients} />
      </div>
    </div>
  );
}

export default PatientDirectory;

import {
  UserAgentApplication,
  ClientAuthError,
  InteractionRequiredAuthError,
  Account,
  AuthResponse,
  Configuration,
} from "msal";
// import { b2cPolicies } from "./policies";
import { b2cPolicies, dev_b2cPolicies } from "./policies";
import { logoutRedirectUri, redirectUri } from "../utils/globalConstants";
import { initFetchRequest, initPostRequest } from "../utils/helperFunctions";
import {
  API_PREFIX,
  API_Scope_Domain,
  MSALClientID,
  API_APP_ID_URI_Identifier,
  isDevelopmentEnvironment,
} from "../utils/globalConstants";

import React from "react";

export const isDevelopment = isDevelopmentEnvironment; // If in development environment

let appConfig;
let msalConfig: Configuration;

// The current application coordinates were pre-registered in a B2C tenant.
appConfig = {
  b2cScopes: [
    API_Scope_Domain + "/" + API_APP_ID_URI_Identifier + "/Bella.Read",
  ],

  webApi: API_PREFIX + "/api/SymptomStudy/filter/dashboard",
};

// Reference: https://github.com/syncweek-react-aad/react-aad/issues/95#issuecomment-513178409
// TODO: Potentially come back and change this later.
const cacheLocation: "localStorage" = "localStorage";

if (isDevelopment) {
  /**********************DEVELOPMENTSERVER MSALCONFIG********************************* */

  // Configuration to initialize MSAL
  msalConfig = {
    auth: {
      clientId: MSALClientID,
      authority: dev_b2cPolicies.authorities.signUpSignIn.authority,
      validateAuthority: false,
      redirectUri: redirectUri,
      postLogoutRedirectUri: logoutRedirectUri,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: true,
    },
  };
} else {
  /**********************PRODUCTION MSALCONFIG********************************* */
  // Configuration to initialize MSAL
  msalConfig = {
    auth: {
      clientId: MSALClientID, // This is your client ID
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      validateAuthority: false,
      redirectUri: redirectUri,
      postLogoutRedirectUri: logoutRedirectUri,
    },
    cache: {
      cacheLocation,
      storeAuthStateInCookie: true,
    },
  };
}

// Request to signin, returns an idToken
const loginRequest = {
  scopes: appConfig.b2cScopes,
  prompt: "login",
  login_hint: "",
};

// Request to acquire a token for resource access
const tokenRequest = {
  scopes: appConfig.b2cScopes,
  prompt: "login",
  login_hint: "",
};

let msalObj: UserAgentApplication;

/**
 * Initializes a new UserAgentApplication.
 */

export const initNewMsalObject = async (
  isChangingPassword: Boolean
): Promise<UserAgentApplication> => {
  // if local storage has returned the user has tried changing password
  if (isChangingPassword) {
    if (isDevelopment) {
      msalConfig.auth.authority =
        dev_b2cPolicies.authorities.changePassword.authority;
    } else {
      msalConfig.auth.authority =
        b2cPolicies.authorities.changePassword.authority;
    }

    try {
      msalObj = msalObj || new UserAgentApplication(msalConfig);
      msalObj.loginRedirect(loginRequest);

      delete window.localStorage["msal.error.description"];
      delete window.localStorage["isDoctor"];
      return msalObj;
    } catch (err: any) {
      console.log(err.message);
    }
  } else {
    msalObj = msalObj || new UserAgentApplication(msalConfig);
  }
  return msalObj;
};

/**
 * Authenticates users using MSAL then retrieves and stores access token. Generates error
 * message at top right if authentication fails.
 *
 */
export const signIn = async () => {
  try {
    const msalObj = await initNewMsalObject(false);
    if (msalObj.getLoginInProgress()) {
      signOut();
    } else {
      msalObj.loginRedirect(loginRequest);
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Gets the account information of the current authenticated user.
 */
export const getAccount = (): Account | null => {
  if (!msalObj) {
    return null;
  }
  return msalObj.getAccount();
};

// gets the Azure AD username of the current authenticated account
export const getUserName = (): string => {
  return msalObj.getAccount().userName;
};

// gets the display username of the current authenticated account
export const getSignedInUserUsername = (): string => {
  if (
    msalObj.getAccount().name === undefined ||
    msalObj.getAccount().name === null ||
    msalObj.getAccount().name === "unknown"
  ) {
    return msalObj.getAccount().idTokenClaims.extension_Username;
  } else {
    return msalObj.getAccount().name;
  }
};

// gets the display name of the current authenticated account
export const getSignedInUserFullName = (): string => {
  return (
    msalObj.getAccount().idTokenClaims.given_name +
    " " +
    msalObj.getAccount().idTokenClaims.family_name
  );
};

// gets the display name of the current authenticated account
export const getSignedInUserFirstName = (): string => {
  return msalObj.getAccount().idTokenClaims.given_name;
};
// gets the display name of the current authenticated account
export const getSignedInUserLastName = (): string => {
  return msalObj.getAccount().idTokenClaims.family_name;
};

// gets the accountIdentifier of the current authenticated account
export const getAccountID = (): string => {
  return msalObj.getAccount()?.accountIdentifier;
};

// determines whether the user has an active session
export const isUserSignedIn = () => {
  const account = msalObj.getAccount();
  return account !== null;
};

// Triggers user sign out.
export const signOut = () => {
  try {
    // Clears isDoctor and other auth information
    // localStorage.clear();
    delete localStorage["msal.error.description"];
    delete localStorage["isDoctor"];
    msalObj.logout();
  } catch (err) {
    console.error(err);
  }
};

function getAccessTokenFromUrl(url: string) {
  //     // Extract the fragment part of the URL
  const fragment = url.split("#")[1];

  // Convert the fragment to an object
  const params = new URLSearchParams(fragment);

  // Get the access token
  const accessToken = params.get("access_token");

  return accessToken;
}
/**
 * Attemps to acquire a user access token silently.
 *
 * @return {AuthResponse} The token.
 */
export const getToken = async (): Promise<
  AuthResponse | undefined | string
> => {
  try {
    if (process.env.NODE_ENV === "test") {
      return Promise.resolve(undefined);
    }
    const msalObj = await initNewMsalObject(false);

    if (msalObj.getLoginInProgress()) {
      signOut();
      return Promise.resolve(undefined);
    }

    if (!isUserSignedIn()) {
      msalObj.acquireTokenRedirect(tokenRequest);
      return;
    }
    const tokenResponse = await msalObj.acquireTokenSilent(tokenRequest);
    return tokenResponse;
  } catch (err: any) {
    if (err instanceof ClientAuthError) {
      signIn();
      return Promise.resolve(undefined); // Return here to avoid continuing execution
    } else if (err instanceof InteractionRequiredAuthError) {
      try {
        // Token captured successfully
        const currentUrl = window.location.href;
        const urlAccessToken = getAccessTokenFromUrl(currentUrl) || "";
        const accessToken = urlAccessToken;
        if (accessToken) {
          console.log({ accessToken });
          return Promise.resolve(accessToken);
        } else {
          msalObj.acquireTokenRedirect(tokenRequest);
        }
        return Promise.resolve(undefined); // Redirects; do not continue
      } catch (err: any) {
        console.log("getToken:: An unexpected error occurred:", err.message);
      }
    }
    console.log("getToken:: An unexpected error occurred:", err.message);
    return Promise.reject(err);
  }
};

export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    if (process.env.NODE_ENV === "test") {
      return;
    }

    const tokenResponse = await getToken();
    if (typeof tokenResponse === "string") {
      console.log({ tokenResponse });
      return tokenResponse;
    }
    if (tokenResponse) {
      return tokenResponse.accessToken;
    }
  } catch (err: any) {
    console.log("getAccessToken:: An unexpected error occurred:", err.message);
  }
};

/**
 * Checks to see if the user is a doctor in the database.
 *
 * @return {Promise<boolean>} A promise containing whether the user is a doctor or not.
 */
export const isUserADoctor = async (): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();
    const request = initFetchRequest("/api/FoodStudy/isDoctor", accessToken);
    const response = await fetch(request);
    const isDoctor: boolean = await response.json();
    return isDoctor;
  } catch (err) {
    return false;
  }
};

/**
 * Checks to see if the user has checked the TOS in the database.
 *
 * @return {Promise<boolean>} A promise containing whether the user has checked the TOS or not.
 */
export const hasTOSChecked = async (): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();
    const request = initPostRequest("/api/FoodStudy/checktos", accessToken);

    const response = await fetch(request);
    const checkedTOS: boolean = (await response.text()) === "true";
    return checkedTOS;
  } catch (err) {
    return false;
  }
};

/**
 * Creates an account for the user in the database and adds them to the patient group.
 *
 * @return {Promise<boolean>} A promise containing whether the user has been added
 * to the patient group or not.
 */
export const createUserDBAndAddToPatientGroup = async (): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();
    const request = initPostRequest(
      "/Users/CreateUserDBAndAddToPatientGroup",
      accessToken
    );

    const response = await fetch(request);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

/**
 * Checks to see if the user has checked the TOS in the database.
 *
 * @return {Promise<boolean>} A promise containing whether the user has checked the TOS or not.
 */
export const checkTOS = async (): Promise<boolean> => {
  try {
    const accessToken = await getAccessToken();
    const request = initPostRequest("/Users/UpdateTOSV2", accessToken);

    const response = await fetch(request);
    const wasChecked: boolean = response.status === 204;
    return wasChecked;
  } catch (err) {
    return false;
  }
};

/**
 * Gets the Signed user ID from the database.
 *
 * @return {Promise<number | null>} A promise containing the user ID.
 */
export const getSignedInUserId = async (): Promise<number | null> => {
  try {
    const accessToken = await getAccessToken();
    const request = initFetchRequest("/Users/GetSignedInUserId", accessToken);
    const response = await fetch(request);
    const userId: number = await response.json();
    return userId;
  } catch (err) {
    console.error("Cannot get user ID: " + err);
    // Handle errors
    return null; // return -1 or any invalid user ID when an error occurs
  }
};

export const AuthContext = React.createContext({
  authID: "",
  userID: "",
});

export const PatientContext = React.createContext({
  patientAuthID: "",
  patientID: "",
});

import { getAccessToken } from "../auth/auth";
import { initFetchRequest, initPostRequest } from "../utils/helperFunctions";
import { fetchData } from "../utils/helperFunctions";
// This function is used to get specific data for graph display and filters data on the frontend
export const getGraphData = async (
  graphType,
  gender,
  startAge,
  endAge,
  startDate,
  endDate
) => {
  startDate = startDate.toISOString().split("T")[0];
  endDate = endDate.toISOString().split("T")[0];
  graphType = graphType.toLowerCase();
  var graphEndpoint = `/api/patients/filter?startDate=${startDate}&endDate=${endDate}&youngestAge=${startAge}&oldestAge=${endAge}&gender=${gender}&graphType=${graphType}`;
  var token = await getAccessToken();
  var myRequest = initFetchRequest(graphEndpoint, token);
  var data = await fetchData(myRequest);
  return data;
};

// TODO: MOve to patient calls folder
export const getTableData = async (
  graphType,
  startDate,
  endDate,
  patientID
) => {
  startDate = startDate.toISOString();
  endDate = endDate.toISOString();
  graphType = graphType.toLowerCase();
  var graphEndpoint;
  if (graphType === "micronutrients") {
    graphEndpoint = `/api/patients/DailyNutrientTotals`;
  } else {
    graphEndpoint = `/api/patients/DailyMacroNutrientTotals`;
  }
  var token = await getAccessToken();

  var body = {
    period: 5,
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    organizationIDs: null,
    doctorIDs: null,
    studyIDs: null,
    userIDs: [patientID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  return data;
};

export const getExportData = async (startDate, endDate, patientID) => {
  const graphEndpoint = `/api/patients/DailyNutrientTotalsV2`;
  const token = await getAccessToken();

  var body = {
    period: 5,
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    userIDs: [patientID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  return data;
};

export const getStudyData = async (startDate, endDate, userID, studyID) => {
  const graphEndpoint = `/api/Study/AverageDailyNutrientConsumptionForStudy`;
  const token = await getAccessToken();

  var body = {
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    userIDs: [userID],
    studyIDs: [studyID],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  data.$values = data.$values.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        let newKey = key.startsWith("avg") ? key.replace(/^avg/, "") : key;
        newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
        newItem[newKey] = item[key];
      }
    }
    return newItem;
  });
  return data;
};

const firstNames = [
  "Hellen",
  "John",
  "Emily",
  "Oliver",
  "Ava",
  "William",
  "Isabella",
  "James",
  "Sophia",
  "George",
  "Mia",
  "Benjamin",
  "Charlotte",
  "Lucas",
  "Amelia",
  "Ethan",
  "Harper",
  "Logan",
  "Evelyn",
  "Alexander",
  "Abigail",
  "Elijah",
  "Emily",
  "Julian",
  "Lily",
  "Gabriel",
  "Madison",
  "Michael",
  "Victoria",
  "Daniel",
  "Jessica",
  "Anthony",
];

const lastNames = [
  "Armors",
  "Deer",
  "Slade",
  "Baker",
  "Hill",
  "Walker",
  "Martin",
  "White",
  "Hall",
  "Thompson",
  "Moore",
  "Johnson",
  "Williams",
  "Jones",
  "Brown",
  "Davis",
  "Miller",
  "Wilson",
  "Garcia",
  "Rodriguez",
  "Martinez",
  "Hernandez",
  "Lopez",
  "Gonzalez",
  "Watson",
  "Harris",
  "Nelson",
];

export const getRandomName = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return `${firstName} ${lastName}`;
};
export const getSymptomsData = async (studyID, startDate, endDate) => {
  const graphEndpoint = `/api/SymptomStudy/GetStudySymptoms`;
  const token = await getAccessToken();

  var body = {
    period: 5,
    customStartDateTime: startDate,
    customEndDateTime: endDate,
    organizationIDs: [0],
    doctorIDs: [0],
    studyIDs: [studyID],
    userIDs: [0],
  };

  var myRequest = initPostRequest(graphEndpoint, token, JSON.stringify(body));
  var data = await fetchData(myRequest);
  const allSymptoms = [];
  // Loop over each entry in the $values array
  data.$values.forEach((entry) => {
    // Check if the symptoms object has a $values array and push it to allSymptoms
    if (entry.symptoms && entry.symptoms.$values) {
      const values = entry.symptoms.$values.map((symptom) => ({
        ...symptom,
        displayName: `${entry.givenName} ${entry.surname}`, // symptom.displayName || getRandomName(), // TODO: remove this after API get fixed
      }));
      allSymptoms.push(...values);
    }
  });
  return allSymptoms;
};

export const getInactiveUsers = async (
  customStartDateTime,
  customEndDateTime,
  studyID
) => {
  const token = await getAccessToken();
  try {
    var body = {
      customStartDateTime,
      customEndDateTime,
      studyIDs: [studyID],
    };

    const request = initPostRequest(
      "/api/Study/GetInactiveUsers",
      token,
      JSON.stringify(body)
    );
    const response = await fetch(request);
    try {
      const inactiveUsers = await response.json();
      if (inactiveUsers) {
        return inactiveUsers;
      }
    } catch (err) {
      return []; //is not valid JSON
    }
    return [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

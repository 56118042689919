import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import DropDown from "./DropDown";
import { Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "./ViewPatientsModal.css";
import { convertDate } from "../../../../utils/helperFunctions";
import { Link } from "react-router-dom";
import { downloadIndiviudalPatientData } from "../../../../API/PatientDirectoryCalls";
import { json2csv } from "json-2-csv";
import LoadingIcon from "../../../../components/common/LoadingIcon";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onClose: () => void;
  show: boolean;
  study: any;
  setShowInviteModal: (val: boolean) => void;
}

interface Patient {
  patientID: string;
  fullName: string;
  status: string;
  enrolled: string;
  unenrolled: string;
}

const ViewPatientsModal = ({
  onClose,
  show,
  study,
  setShowInviteModal,
}: Props) => {
  const [sortBy, setSortBy] = useState("lastName");
  const [status, setStatus] = useState("all");
  const [sortedData, setSortedData] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!study || study.patients.$values.length === 0) {
      return;
    }

    let sorted = study?.patients?.$values?.sort((a: any, b: any) => {
      switch (sortBy) {
        case "lastName":
          return (a.surname || "").localeCompare(b.surname || "");
        case "firstName":
          return (a.givenName || "").localeCompare(b.givenName || "");
        case "dateEnrolled":
          return (a.startDate || "").localeCompare(b.startDate || "");
        case "dateUnenrolled":
          return (a.endDate || "").localeCompare(b.endDate || "");
        default:
          return 0;
      }
    });

    sorted = sorted.map((patient: any) => {
      return {
        patientID: patient.patientID,
        fullName: `${patient.givenName} ${patient.surname}`,
        status: patient.endDate ? "NOT ACTIVE" : "ACTIVE",
        enrolled: convertDate(patient.startDate, true),
        unenrolled: is500Years(patient.endDate)
          ? ""
          : patient.endDate
            ? convertDate(patient.endDate, true)
            : "",
      } as Patient;
    });

    setSortedData(sorted);
  }, [study, sortBy]);

  if (!study) {
    return null;
  }

  const handleSortByChange = (e: any) => {
    setSortBy(e.target.value);
  };
  const handleStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  const is500Years = (dateString: string) => {
    if (!dateString) {
      return false;
    }

    //500 years from now
    const refDate = new Date();
    refDate.setFullYear(new Date().getFullYear() + 500);

    const dateRecord = new Date(dateString.split("T")[0]);
    return dateRecord > refDate;
  };

  const downloadData = async () => {
    if (!sortedData.length) {
      return;
    }
    setLoading(true);

    try {
      const csv = json2csv(sortedData);
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `All patients.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDownloadSingleRecord = async (patient: Patient) => {
    setLoading(true);
    await downloadIndiviudalPatientData(patient.patientID, patient.fullName);
    setLoading(false);
  };
  return (
    <Modal open={show} onClose={onClose} aria-labelledby="patient-list">
      <div className="invite-patient-modal box-container top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-1/2 h-3/4 absolute overflow-auto">
        {loading ? (
          <div className="patientTableLoadingSpinner">
            <LoadingIcon />
            <h2 style={{ textAlign: "center", marginRight: "37.5px" }}>
              One moment, retrieving data...
            </h2>
          </div>
        ) : (
          <>
            <h3 className="font-bold text-gray-500">
              {`${study.name}: Patient List`.toUpperCase()}
            </h3>
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#7ecfe4",
              }}>
              <CloseIcon />
            </IconButton>
            <div className="flex justify-between mt-3 mb-8 ">
              <span className="w-[200px] flex justify-between items-center gap-2">
                <label className="whitespace-nowrap">Sort By:</label>
                <DropDown
                  value={sortBy}
                  data={[
                    { name: "LAST NAME", value: "lastName" },
                    { name: "FIRST NAME", value: "firstName" },
                    { name: "DATE ENROLLED", value: "dateEnrolled" },
                    { name: "DATE UNENROLLED", value: "dateUnenrolled" },
                  ]}
                  onChangeFunction={handleSortByChange}
                />
              </span>
              <span className="w-[200px] flex justify-between items-center gap-2">
                <label className="whitespace-nowrap">Status</label>
                <DropDown
                  value={status}
                  data={[{ name: "ALL", value: "all" }]}
                  onChangeFunction={handleStatusChange}
                />
              </span>
              <Button
                onClick={() => {
                  setShowInviteModal(true);
                  onClose();
                }}
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                sx={{
                  color: "white",
                  background: "#5FBDD6",
                  borderRadius: "10px",
                  textTransform: "none",
                  width: "160px",
                  padding: "5px 20px",
                }}>
                Invite Patient
              </Button>
              <Button
                onClick={downloadData}
                variant="contained"
                startIcon={<FontAwesomeIcon icon={faDownload} />}
                sx={{
                  color: "white",
                  background: "#5FBDD6",
                  borderRadius: "10px",
                  textTransform: "none",
                  width: "160px",
                  padding: "5px 20px",
                }}>
                Download
              </Button>
            </div>
            <div className="patient-list-table bg-gray-50">
              {/* Header */}
              <div className="patient-list-row patient-list-header">
                <span className="hide"></span>
                <span className="b-right b-left">FIRST NAME, LAST NAME</span>
                <span className="b-right">STATUS</span>
                <span className="b-right">DATE ENROLLED</span>
                <span className="b-right">DATE UNENROLLED</span>
                <span className="hide"></span>
              </div>

              {/* Table */}
              {sortedData.map((patient: any, index: number) => {
                return (
                  <div className="patient-list-row" key={patient.patientID}>
                    <span className="row-number">{index + 1}</span>
                    <span className="b-right b-left ">
                      <Link
                        to={`/user/profile/${patient.patientID}`}
                        className="text-blue font-bold">
                        {patient.fullName.toUpperCase()}
                      </Link>
                    </span>
                    <span className="b-right">{patient.status}</span>
                    <span className="b-right">{patient.enrolled}</span>
                    <span className="b-right">{patient.unenrolled}</span>
                    <span
                      data-testid="patient-download-button"
                      className="download cursor-pointer"
                      onClick={() => handleDownloadSingleRecord(patient)}>
                      <FontAwesomeIcon
                        className="primary-blue"
                        icon={faDownload}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ViewPatientsModal;

import LoadingIcon from "../../../../components/common/LoadingIcon";
const CommonLoadingIndicator = () => (
  <div className="patientTableLoadingSpinner">
    <LoadingIcon />
    <h2 style={{ textAlign: "center", marginRight: "37.5px" }}>
      One moment, retrieving data...
    </h2>
  </div>
);

export default CommonLoadingIndicator;

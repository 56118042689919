export const micronutrientsColumns = [
  { field: "consumedDate", headerName: "Consumed Date", width: 200 },
  { field: "nitrogenG", headerName: "Nitrogen (g)", width: 200 },
  { field: "proteinG", headerName: "Protein (g)", width: 200 },
  {
    field: "totalLipidFatG",
    headerName: "Total Lipid (Fat) (g)",
    width: 200,
  },
  {
    field: "carbohydrateByDifferenceG",
    headerName: "Carbohydrate by Difference (g)",
    width: 200,
  },
  { field: "ashG", headerName: "Ash (g)", width: 200 },
  { field: "energyKcal", headerName: "Energy (kcal)", width: 200 },
  { field: "energyKJ", headerName: "Energy (kJ)", width: 200 },
  { field: "starchG", headerName: "Starch (g)", width: 200 },
  { field: "sucroseG", headerName: "Sucrose (g)", width: 200 },
  {
    field: "glucoseDextroseG",
    headerName: "Glucose (Dextrose) (g)",
    width: 200,
  },
  { field: "fructoseG", headerName: "Fructose (g)", width: 200 },
  { field: "lactoseG", headerName: "Lactose (g)", width: 200 },
  { field: "maltoseG", headerName: "Maltose (g)", width: 200 },
  { field: "alcoholEthylG", headerName: "Alcohol (Ethyl) (g)", width: 200 },
  {
    field: "specificGravitySpGr",
    headerName: "Specific Gravity (Sp. Gr.)",
    width: 200,
  },
  { field: "aceticAcidMg", headerName: "Acetic Acid (mg)", width: 200 },
  { field: "lacticAcidMg", headerName: "Lactic Acid (mg)", width: 200 },
  {
    field: "carbohydrateBySummationG",
    headerName: "Carbohydrate by Summation (g)",
    width: 200,
  },
  { field: "waterG", headerName: "Water (g)", width: 200 },
  { field: "sorbitolG", headerName: "Sorbitol (g)", width: 200 },
  { field: "caffeineMg", headerName: "Caffeine (mg)", width: 200 },
  { field: "theobromineMg", headerName: "Theobromine (mg)", width: 200 },
  {
    field: "sugarsTotalNleaG",
    headerName: "Sugars, Total (NLEA) (g)",
    width: 200,
  },
  {
    field: "carbohydrateOtherG",
    headerName: "Carbohydrate, Other (g)",
    width: 200,
  },
  { field: "galactoseG", headerName: "Galactose (g)", width: 200 },
  { field: "xylitolG", headerName: "Xylitol (g)", width: 200 },
  {
    field: "fiberTotalDietaryG",
    headerName: "Fiber, Total Dietary (g)",
    width: 200,
  },
  { field: "riboseG", headerName: "Ribose (g)", width: 200 },
  { field: "fiberSolubleG", headerName: "Fiber, Soluble (g)", width: 200 },
  {
    field: "fiberInsolubleG",
    headerName: "Fiber, Insoluble (g)",
    width: 200,
  },
  { field: "totalFatNleaG", headerName: "Total Fat (NLEA) (g)", width: 200 },
  {
    field: "totalSugarAlcoholsG",
    headerName: "Total Sugar Alcohols (g)",
    width: 200,
  },
  { field: "calciumCaMg", headerName: "Calcium (Ca) (mg)", width: 200 },
  { field: "chlorineClMg", headerName: "Chlorine (Cl) (mg)", width: 200 },
  { field: "ironFeMg", headerName: "Iron (Fe) (mg)", width: 200 },
  { field: "magnesiumMgMg", headerName: "Magnesium (Mg) (mg)", width: 200 },
  { field: "phosphorusPMg", headerName: "Phosphorus (P) (mg)", width: 200 },
  { field: "potassiumKMg", headerName: "Potassium (K) (mg)", width: 200 },
  { field: "sodiumNaMg", headerName: "Sodium (Na) (mg)", width: 200 },
  { field: "sulfurSMg", headerName: "Sulfur (S) (mg)", width: 200 },
  { field: "zincZnMg", headerName: "Zinc (Zn) (mg)", width: 200 },
  { field: "chromiumCrUg", headerName: "Chromium (Cr) (µg)", width: 200 },
  { field: "cobaltCoUg", headerName: "Cobalt (Co) (µg)", width: 200 },
  { field: "copperCuMg", headerName: "Copper (Cu) (mg)", width: 200 },
  { field: "fluorideFUg", headerName: "Fluoride (F) (µg)", width: 200 },
  { field: "iodineIUg", headerName: "Iodine (I) (µg)", width: 200 },
  { field: "molybdenumMoUg", headerName: "Molybdenum (Mo) (µg)", width: 200 },
  { field: "seleniumSeUg", headerName: "Selenium (Se) (µg)", width: 200 },
  { field: "vitaminAIuIu", headerName: "Vitamin A (IU)", width: 200 },
  { field: "retinolUg", headerName: "Retinol (µg)", width: 200 },
  { field: "vitaminARaeUg", headerName: "Vitamin A, RAE (µg)", width: 200 },
  { field: "caroteneBetaUg", headerName: "Carotene, beta (µg)", width: 200 },
  {
    field: "caroteneAlphaUg",
    headerName: "Carotene, alpha (µg)",
    width: 200,
  },
  {
    field: "vitaminEAlphaTocopherolMg",
    headerName: "Vitamin E (alpha-tocopherol) (mg)",
    width: 200,
  },
  { field: "vitaminDIu", headerName: "Vitamin D (IU)", width: 200 },
  {
    field: "vitaminD2ErgocalciferolUg",
    headerName: "Vitamin D2 (ergocalciferol) (µg)",
    width: 200,
  },
  {
    field: "vitaminD3CholecalciferolUg",
    headerName: "Vitamin D3 (cholecalciferol) (µg)",
    width: 200,
  },
  {
    field: "25HydroxycholecalciferolUg",
    headerName: "25-hydroxycholecalciferol (µg)",
    width: 200,
  },
  {
    field: "vitaminDD2D3Ug",
    headerName: "Vitamin D (D2 + D3) (µg)",
    width: 200,
  },
  { field: "phytoeneUg", headerName: "Phytoene (µg)", width: 200 },
  { field: "phytoflueneUg", headerName: "Phytofluene (µg)", width: 200 },
  { field: "zeaxanthinUg", headerName: "Zeaxanthin (µg)", width: 200 },
  {
    field: "cryptoxanthinBetaUg",
    headerName: "Cryptoxanthin, beta (µg)",
    width: 200,
  },
  { field: "luteinUg", headerName: "Lutein (µg)", width: 200 },
  { field: "lycopeneUg", headerName: "Lycopene (µg)", width: 200 },
  {
    field: "luteinZeaxanthinUg",
    headerName: "Lutein + zeaxanthin (µg)",
    width: 200,
  },
  {
    field: "vitaminELabelEntryPrimarilyIu",
    headerName: "Vitamin E, label entry primarily (IU)",
    width: 200,
  },
  {
    field: "tocopherolBetaMg",
    headerName: "Tocopherol, beta (mg)",
    width: 200,
  },
  {
    field: "tocopherolGammaMg",
    headerName: "Tocopherol, gamma (mg)",
    width: 200,
  },
  {
    field: "tocopherolDeltaMg",
    headerName: "Tocopherol, delta (mg)",
    width: 200,
  },
  {
    field: "tocotrienolAlphaMg",
    headerName: "Tocotrienol, alpha (mg)",
    width: 200,
  },
  {
    field: "tocotrienolBetaMg",
    headerName: "Tocotrienol, beta (mg)",
    width: 200,
  },
  {
    field: "tocotrienolGammaMg",
    headerName: "Tocotrienol, gamma (mg)",
    width: 200,
  },
  {
    field: "tocotrienolDeltaMg",
    headerName: "Tocotrienol, delta (mg)",
    width: 200,
  },
  { field: "boronBUg", headerName: "Boron (B) (µg)", width: 200 },
  { field: "nickelNiUg", headerName: "Nickel (Ni) (µg)", width: 200 },
  { field: "vitaminEMgAte", headerName: "Vitamin E (mg_ate)", width: 200 },
  {
    field: "cisBetaCaroteneUg",
    headerName: "cis-beta-Carotene (µg)",
    width: 200,
  },
  { field: "cisLycopeneUg", headerName: "cis-Lycopene (µg)", width: 200 },
  {
    field: "cisLuteinZeaxanthinUg",
    headerName: "cis-Lutein/Zeaxanthin (µg)",
    width: 200,
  },
  {
    field: "vitaminCTotalAscorbicAcidMg",
    headerName: "Vitamin C, Total Ascorbic Acid (mg)",
    width: 200,
  },
  { field: "thiaminMg", headerName: "Thiamin (mg)", width: 200 },
  { field: "riboflavinMg", headerName: "Riboflavin (mg)", width: 200 },
  { field: "niacinMg", headerName: "Niacin (mg)", width: 200 },
  {
    field: "pantothenicAcidMg",
    headerName: "Pantothenic Acid (mg)",
    width: 200,
  },
  { field: "vitaminB6Mg", headerName: "Vitamin B6 (mg)", width: 200 },
  { field: "biotinUg", headerName: "Biotin (µg)", width: 200 },
  { field: "folateTotalUg", headerName: "Folate, Total (µg)", width: 200 },
  { field: "vitaminB12Ug", headerName: "Vitamin B12 (µg)", width: 200 },
  { field: "cholineTotalMg", headerName: "Choline, Total (mg)", width: 200 },
  { field: "inositolMg", headerName: "Inositol (mg)", width: 200 },
  { field: "menaquinone4Ug", headerName: "Menaquinone-4 (µg)", width: 200 },
  {
    field: "dihydrophylloquinoneUg",
    headerName: "Dihydrophylloquinone (µg)",
    width: 200,
  },
  {
    field: "vitaminKPhylloquinoneUg",
    headerName: "Vitamin K (Phylloquinone) (µg)",
    width: 200,
  },
  { field: "folicAcidUg", headerName: "Folic Acid (µg)", width: 200 },
  { field: "folateFoodUg", headerName: "Folate, Food (µg)", width: 200 },
  {
    field: "5MethylTetrahydrofolate5MthfUg",
    headerName: "5-Methyl Tetrahydrofolate (5-MTHF) (µg)",
    width: 200,
  },
  { field: "folateDfeUg", headerName: "Folate, DFE (µg)", width: 200 },
  {
    field: "10FormylFolicAcid10hcofaUg",
    headerName: "10-Formyl Folic Acid (10HCOFA) (µg)",
    width: 200,
  },
  {
    field: "5FormyltetrahydrofolicAcid5Hcoh4Ug",
    headerName: "5-Formyltetrahydrofolic Acid (5-HCOH4) (µg)",
    width: 200,
  },
  { field: "cholineFreeMg", headerName: "Choline, Free (mg)", width: 200 },
  {
    field: "cholineFromPhosphocholineMg",
    headerName: "Choline, from Phosphocholine (mg)",
    width: 200,
  },
  {
    field: "cholineFromPhosphotidylCholineMg",
    headerName: "Choline, from Phosphotidyl Choline (mg)",
    width: 200,
  },
  {
    field: "cholineFromGlycerophosphocholineMg",
    headerName: "Choline, from Glycerophosphocholine (mg)",
    width: 200,
  },
  { field: "betaineMg", headerName: "Betaine (mg)", width: 200 },
  {
    field: "cholineFromSphingomyelinMg",
    headerName: "Choline, from Sphingomyelin (mg)",
    width: 200,
  },
  { field: "serineG", headerName: "Serine (g)", width: 200 },
  { field: "tryptophanG", headerName: "Tryptophan (g)", width: 200 },
  { field: "threonineG", headerName: "Threonine (g)", width: 200 },
  { field: "isoleucineG", headerName: "Isoleucine (g)", width: 200 },
  { field: "leucineG", headerName: "Leucine (g)", width: 200 },
  { field: "lysineG", headerName: "Lysine (g)", width: 200 },
  { field: "methionineG", headerName: "Methionine (g)", width: 200 },
  { field: "cystineG", headerName: "Cystine (g)", width: 200 },
  { field: "phenylalanineG", headerName: "Phenylalanine (g)", width: 200 },
  { field: "tyrosineG", headerName: "Tyrosine (g)", width: 200 },
  { field: "valineG", headerName: "Valine (g)", width: 200 },
  { field: "arginineG", headerName: "Arginine (g)", width: 200 },
  { field: "histidineG", headerName: "Histidine (g)", width: 200 },
  { field: "alanineG", headerName: "Alanine (g)", width: 200 },
  { field: "asparticAcidG", headerName: "Aspartic Acid (g)", width: 200 },
  { field: "glutamicAcidG", headerName: "Glutamic Acid (g)", width: 200 },
  { field: "glycineG", headerName: "Glycine (g)", width: 200 },
  { field: "prolineG", headerName: "Proline (g)", width: 200 },
  { field: "hydroxyprolineG", headerName: "Hydroxyproline (g)", width: 200 },
  { field: "cysteineG", headerName: "Cysteine (g)", width: 200 },
  { field: "glutamineG", headerName: "Glutamine (g)", width: 200 },
  { field: "taurineG", headerName: "Taurine (g)", width: 200 },
  { field: "sugarsAddedG", headerName: "Sugars, Added (g)", width: 200 },
  {
    field: "vitaminEAddedMg",
    headerName: "Vitamin E, Added (mg)",
    width: 200,
  },
  {
    field: "vitaminB12AddedUg",
    headerName: "Vitamin B12, Added (µg)",
    width: 200,
  },
  { field: "cholesterolMg", headerName: "Cholesterol (mg)", width: 200 },
  {
    field: "fattyAcidsTotalTransG",
    headerName: "Fatty Acids, Total Trans (g)",
    width: 200,
  },
  {
    field: "fattyAcidsTotalSaturatedG",
    headerName: "Fatty Acids, Total Saturated (g)",
    width: 200,
  },
  { field: "40G", headerName: "4:0 (g)", width: 200 },
  { field: "60G", headerName: "6:0 (g)", width: 200 },
  { field: "80G", headerName: "8:0 (g)", width: 200 },
  { field: "100G", headerName: "10:0 (g)", width: 200 },
  { field: "120G", headerName: "12:0 (g)", width: 200 },
  { field: "140G", headerName: "14:0 (g)", width: 200 },
  { field: "160G", headerName: "16:0 (g)", width: 200 },
  { field: "180G", headerName: "18:0 (g)", width: 200 },
  { field: "200G", headerName: "20:0 (g)", width: 200 },
  { field: "181G", headerName: "18:1 (g)", width: 200 },
  { field: "182G", headerName: "18:2 (g)", width: 200 },
  { field: "183G", headerName: "18:3 (g)", width: 200 },
  { field: "204G", headerName: "20:4 (g)", width: 200 },
  { field: "226N3DhaG", headerName: "22:6 n-3 (DHA) (g)", width: 200 },
  { field: "220G", headerName: "22:0 (g)", width: 200 },
  { field: "141G", headerName: "14:1 (g)", width: 200 },
  { field: "161G", headerName: "16:1 (g)", width: 200 },
  { field: "184G", headerName: "18:4 (g)", width: 200 },
  { field: "201G", headerName: "20:1 (g)", width: 200 },
  { field: "205N3EpaG", headerName: "20:5 n-3 (EPA) (g)", width: 200 },
  { field: "221G", headerName: "22:1 (g)", width: 200 },
  { field: "225N3DpaG", headerName: "22:5 n-3 (DPA) (g)", width: 200 },
  { field: "141TG", headerName: "14:1 t (g)", width: 200 },
  { field: "phytosterolsMg", headerName: "Phytosterols (mg)", width: 200 },
  { field: "stigmasterolMg", headerName: "Stigmasterol (mg)", width: 200 },
  { field: "campesterolMg", headerName: "Campesterol (mg)", width: 200 },
  {
    field: "betaSitosterolMg",
    headerName: "Beta-sitosterol (mg)",
    width: 200,
  },
  {
    field: "fattyAcidsTotalMonounsaturatedG",
    headerName: "Fatty Acids, Total Monounsaturated (g)",
    width: 200,
  },
  {
    field: "fattyAcidsTotalPolyunsaturatedG",
    headerName: "Fatty Acids, Total Polyunsaturated (g)",
    width: 200,
  },
  { field: "150G", headerName: "15:0 (g)", width: 200 },
  { field: "170G", headerName: "17:0 (g)", width: 200 },
  { field: "240G", headerName: "24:0 (g)", width: 200 },
  { field: "161TG", headerName: "16:1 t (g)", width: 200 },
  { field: "181TG", headerName: "18:1 t (g)", width: 200 },
  { field: "221TG", headerName: "22:1 t (g)", width: 200 },
  {
    field: "182TNotFurtherDefinedG",
    headerName: "18:2 t not further defined (g)",
    width: 200,
  },
  { field: "182IG", headerName: "18:2 i (g)", width: 200 },
  { field: "182TTG", headerName: "18:2 t,t (g)", width: 200 },
  { field: "182ClasG", headerName: "18:2 CLAs (g)", width: 200 },
  { field: "241CG", headerName: "24:1 c (g)", width: 200 },
  { field: "202N6CCG", headerName: "20:2 n-6 c,c (g)", width: 200 },
  { field: "161CG", headerName: "16:1 c (g)", width: 200 },
  { field: "181CG", headerName: "18:1 c (g)", width: 200 },
  { field: "182N6CCG", headerName: "18:2 n-6 c,c (g)", width: 200 },
  { field: "221CG", headerName: "22:1 c (g)", width: 200 },
  { field: "183N6CCCG", headerName: "18:3 n-6 c,c,c (g)", width: 200 },
  { field: "171G", headerName: "17:1 (g)", width: 200 },
  { field: "203G", headerName: "20:3 (g)", width: 200 },
  {
    field: "fattyAcidsTotalTransMonoenoicG",
    headerName: "Fatty Acids, Total Trans-Monoenoic (g)",
    width: 200,
  },
  {
    field: "fattyAcidsTotalTransDienoicG",
    headerName: "Fatty Acids, Total Trans-Dienoic (g)",
    width: 200,
  },
  {
    field: "fattyAcidsTotalTransPolyenoicG",
    headerName: "Fatty Acids, Total Trans-Polyenoic (g)",
    width: 200,
  },
  { field: "130G", headerName: "13:0 (g)", width: 200 },
  { field: "151G", headerName: "15:1 (g)", width: 200 },
  { field: "222G", headerName: "22:2 (g)", width: 200 },
  { field: "110G", headerName: "11:0 (g)", width: 200 },
  {
    field: "epigallocatechin3GallateMg",
    headerName: "Epigallocatechin-3-Gallate (mg)",
    width: 200,
  },
  { field: "inulinG", headerName: "Inulin (g)", width: 200 },
  {
    field: "183N3CCCAlaG",
    headerName: "18:3 n-3 c,c,c (ALA) (g)",
    width: 200,
  },
  { field: "203N3G", headerName: "20:3 n-3 (g)", width: 200 },
  { field: "203N6G", headerName: "20:3 n-6 (g)", width: 200 },
  { field: "204N6G", headerName: "20:4 n-6 (g)", width: 200 },
  { field: "183iG", headerName: "18:3i (g)", width: 200 },
  { field: "215G", headerName: "21:5 (g)", width: 200 },
  { field: "224G", headerName: "22:4 (g)", width: 200 },
  {
    field: "18111T181tN7G",
    headerName: "18:1-11 t (18:1t n-7) (g)",
    width: 200,
  },
  { field: "203N9G", headerName: "20:3 n-9 (g)", width: 200 },
  {
    field: "sugarsTotalIncludingNleaG",
    headerName: "Sugars, Total Including NLEA (g)",
    width: 200,
  },
  { field: "50G", headerName: "5:0 (g)", width: 200 },
  { field: "70G", headerName: "7:0 (g)", width: 200 },
  { field: "90G", headerName: "9:0 (g)", width: 200 },
  { field: "210G", headerName: "21:0 (g)", width: 200 },
  { field: "230G", headerName: "23:0 (g)", width: 200 },
  { field: "121G", headerName: "12:1 (g)", width: 200 },
  { field: "141CG", headerName: "14:1 c (g)", width: 200 },
  { field: "171CG", headerName: "17:1 c (g)", width: 200 },
  { field: "201CG", headerName: "20:1 c (g)", width: 200 },
  { field: "201TG", headerName: "20:1 t (g)", width: 200 },
  { field: "221N9G", headerName: "22:1 n-9 (g)", width: 200 },
  { field: "221N11G", headerName: "22:1 n-11 (g)", width: 200 },
  { field: "182CG", headerName: "18:2 c (g)", width: 200 },
  { field: "183CG", headerName: "18:3 c (g)", width: 200 },
  { field: "183TG", headerName: "18:3 t (g)", width: 200 },
  { field: "203CG", headerName: "20:3 c (g)", width: 200 },
  { field: "223G", headerName: "22:3 (g)", width: 200 },
  { field: "204CG", headerName: "20:4 c (g)", width: 200 },
  { field: "205CG", headerName: "20:5 c (g)", width: 200 },
  { field: "225CG", headerName: "22:5 c (g)", width: 200 },
  { field: "226CG", headerName: "22:6 c (g)", width: 200 },
  { field: "202CG", headerName: "20:2 c (g)", width: 200 },
  {
    field: "transBetaCaroteneUg",
    headerName: "Trans-beta-Carotene (µg)",
    width: 200,
  },
  { field: "transLycopeneUg", headerName: "Trans-Lycopene (µg)", width: 200 },
  {
    field: "cryptoxanthinAlphaUg",
    headerName: "Cryptoxanthin, alpha (µg)",
    width: 200,
  },
];

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { roundDecimal } from "../../../../utils/helperFunctions";
import CommonLoadingIndicator from "./CommonLoadingIndicator";

type TableProps = {
  data: Array<Object>;
};

export function transformDataToRows(data: Array<Object>) {
  return data.map((value: any) => {
    return {
      id: value.$id,
      date: value.consumedDate ? value.consumedDate.split("T")[0] : "",
      protein: roundDecimal((value.proteinG || "0") + "g"),
      carbohydrates: roundDecimal(
        (value.carbohydrateByDifferenceG || "0") + "g"
      ),
      fats: roundDecimal((value.totalLipidFatG || "0") + "g"),
      calories: roundDecimal(
        value.energyKcal || (value.energyKj > 0 ? value.eneryKj / 4.184 : "0")
      ),
    };
  });
}

function MacronutrientTable({ data }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  const columns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "protein", headerName: "Protein", width: 200 },
    {
      field: "carbohydrates",
      headerName: "Carbohydrates",
      width: 200,
    },
    { field: "fats", headerName: "Fats", width: 200 },
    { field: "calories", headerName: "Calories", width: 200 },
  ];

  useEffect(() => {
    if (!data) return;
    try {
      const transformedRows = transformDataToRows(data);

      setTableRows(transformedRows);
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  if (tableRows && dataLoaded) {
    return (
      <DataGrid
        data-testid="macronutrient-table"
        rows={tableRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[5, 10, 20]}
      />
    );
  } else {
    return <CommonLoadingIndicator />;
  }
}

export default MacronutrientTable;

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { roundDecimal } from "../../../../utils/helperFunctions";
import CommonLoadingIndicator from "./CommonLoadingIndicator";

type TableProps = {
  data: any[];
  columns: any[];
};

export function transformDataToRows(data: any[]) {
  const transformedData: Array<Object> = [];
  const columnNames = Object.keys(data[0]);
  data.forEach((value) => {
    let newValue: { [key: string]: any } = {};

    columnNames.forEach((columnName: string) => {
      if (columnName === "$id") {
        newValue["id"] = value[columnName];
      } else if (columnName === "consumedDate") {
        newValue[columnName] = value.consumedDate.split("T")[0];
      } else if (columnName === "userId") {
        newValue[columnName] = value[columnName];
        return;
      } else {
        newValue[columnName] = roundDecimal(
          value[columnName] === null ? 0 : value[columnName]
        );
      }
    });

    transformedData.push(newValue);
  });
  return transformedData;
}

function MicronutrientTable({ data, columns }: TableProps) {
  const [tableRows, setTableRows] = useState<Array<Object>>();
  const [dataLoaded] = useState<Boolean>(true);

  useEffect(() => {
    if (!data) return;
    if (data.length === 0) {
      setTableRows([]);
      return;
    }
    const transformedData: Array<Object> = transformDataToRows(data);
    setTableRows(transformedData);
  }, [data]);

  if (tableRows && dataLoaded) {
    return (
      <DataGrid
        rows={tableRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[10, 25, 50, 100]}
      />
    );
  } else {
    return <CommonLoadingIndicator />;
  }
}

export default MicronutrientTable;

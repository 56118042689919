// This final contains the configuration URIs for initializes
// the MSAL object in auth.ts.
// During local development change these URIs to "http://localhost:3000"

export const TestingLocally = false;
export const logoutRedirectUri = "https://mymeddiary.app";
export const redirectUri = "https://mymeddiary.app";

// MUst be set to development URL

// export const TestingLocally = false;
// export const logoutRedirectUri = "https://mymeddiary-website-development.azurewebsites.net";
// export const redirectUri = "https://mymeddiary-website-development.azurewebsites.net";

// export const TestingLocally = false;
// export const logoutRedirectUri = "https://mymeddiary-website-staging.azurewebsites.net";
// export const redirectUri = "https://mymeddiary-website-staging.azurewebsites.net"; 

// export const TestingLocally = true;
// export const logoutRedirectUri = "http://localhost:3000"; 
// export const redirectUri = "http://localhost:3000"; 


const painSymptoms = [
 "Head",
 "Chest",
 "Left Arm",
 "Right Arm",
 "Left Leg",
 "Right Leg",
 "Stomach",
 "Neck",
 "Genital",
 "Back",
];
const coldFluSymptoms = [
 "Fever",
 "Joint Pain",
 "Dry cough",
 "Sore throat",
 "Fatigue",
 "Headache",
 "Phlegm",
 "Chills",
 "Shortness of breath",
 "Other",
];
const stomachSymptoms = [
 "Stomach Pain",
 "Constipation",
 "Vomiting",
 "Diarrhea",
 "Nausea",
 "Cramps",
 "Gas",
 "Bloating",
 "Unusual Stool",
 "Ulcers",
];

// change the API_PREFIX to do local host server testing
//"https://developement-cra-web-app-one.azurewebsites.net";

//Uncomment the Environment as needed

//Production
export const CRAEnvironment = "Production"
export const API_PREFIX = "https://bellamobileappservice20190922030648.azurewebsites.net";
export const API_APP_ID_URI_Identifier = "api"
export const isDevelopmentEnvironment = false; // If in development environment

export const API_Scope_Domain = "https://ComputingReAppliedB2C.onmicrosoft.com";
export const MSALClientID = "6824c6db-3eea-4d7b-ac40-89406f33e362";


//Staging

// export const CRAEnvironment = "Staging"
// export const API_PREFIX = "https://staging-cra-web-app-one.azurewebsites.net";
// export const API_APP_ID_URI_Identifier = "api-staging"
// export const isDevelopmentEnvironment = false; // If in development environment
// export const API_Scope_Domain = "https://ComputingReAppliedB2C.onmicrosoft.com"
// export const MSALClientID = "1ffa606e-8593-43bd-ba25-d88dc74a271c"

//Development
// export const CRAEnvironment = "Development"
// export const API_PREFIX = "https://developement-cra-web-app-one.azurewebsites.net";
// export const isDevelopmentEnvironment = true; // If in development environment
// export const API_APP_ID_URI_Identifier = "api-dev"
// export const API_Scope_Domain = "https://cradevelopmentdirectory.onmicrosoft.com"
// export const MSALClientID = "539aa630-1113-4dd1-9343-21981f7569aa"


//Local Testing
// export const CRAEnvironment = "Local"
// export const API_PREFIX = "https://localhost:5001";

/** Select Local Backend Environment ****/

/* Production */
// export const API_APP_ID_URI_Identifier = "api"
// export const isDevelopmentEnvironment = false; // If in development environment
// export const API_Scope_Domain = "https://ComputingReAppliedB2C.onmicrosoft.com"
// export const MSALClientID = "6824c6db-3eea-4d7b-ac40-89406f33e362";


/* Staging */
// export const API_APP_ID_URI_Identifier = "api-staging"
// export const isDevelopmentEnvironment = false; // If in development environment
// export const API_Scope_Domain = "https://ComputingReAppliedB2C.onmicrosoft.com"
// export const MSALClientID = "1ffa606e-8593-43bd-ba25-d88dc74a271c"


/* Development */
//export const API_APP_ID_URI_Identifier = "api-dev"
//export const isDevelopmentEnvironment = true; // If in development environment
//export const API_Scope_Domain = "https://cradevelopmentdirectory.onmicrosoft.com"
//export const MSALClientID = "539aa630-1113-4dd1-9343-21981f7569aa"


//export const API_PREFIX = "https://bellamobileappservice20190922030648.azurewebsites.net";
//export const API_PREFIX = "https://developement-cra-web-app-one.azurewebsites.net";
// export const API_PREFIX = "https://localhost:5001";

/* TO DO: Need to set the redirect URI based on the environment 
var logoutRedirectUriString = "";

if (CRAEnvironment === "Production") {
 logoutRedirectUriString ="https://mymeddiary.app";

} else if (CRAEnvironment === "Staging") {
 logoutRedirectUriString ="https://mymeddiary-website-staging.azurewebsites.net";
} else if (CRAEnvironment === "Development") {
 logoutRedirectUriString ="https://mymeddiary-website-development.azurewebsites.net";
} else if (CRAEnvironment === "Local") {
 logoutRedirectUriString ="http://localhost:3000";
} else {
console.log("CRAEnvironment variable is incorrect");
}

export const logoutRedirectUri = logoutRedirectUriString ;
export const redirectUri = logoutRedirectUriString;

*/

export const allSymptomsNoCategory = [
 ...painSymptoms,
 ...coldFluSymptoms,
 ...stomachSymptoms,
];

const allSymptoms = {
 pain: painSymptoms,
 cold: coldFluSymptoms,
 stomach: stomachSymptoms,
};

export const months = {
 1: "January",
 2: "February",
 3: "March",
 4: "April",
 5: "May",
 6: "June",
 7: "July",
 8: "August",
 9: "September",
 10: "October",
 11: "November",
 12: "December",
};

///test comment
export default allSymptoms;